<template>
  <div class="Page">
    <file-drop
      v-if="!file"
      accept="image/jpeg,image/jpg,image/png,image/gif,image/svg+xml"
      pattern=".jpeg,.jpg,.png,.gif,.svg"
      @file="setFile($event.file)"
    />
    <div v-if="file" class="Crop">
      <div class="CropImageContainer">
        <img class="CropImage" ref="image">
      </div>
      <i class="Icon CropPreviewIcon">forward</i>
      <div class="CropPreviewContainer">
        <div class="CropPreview" ref="preview" />
      </div>
    </div>
    <div class="CropOptions" v-if="file">
      <button class="Button Button--s" @click="rotateLeft" title="Rotate left">
        <i class="Icon">rotate_left</i>
      </button>
      <button class="Button Button--s" @click="rotateRight" title="Rotate right">
        <i class="Icon">rotate_right</i>
      </button>
      <button class="Button Button--s" @click="flipX" title="Flip horizontally">
        <i class="Icon">flip</i>
      </button>
      <button class="Button Button--s" @click="flipY" title="Flip vertically">
        <i class="Icon Icon--rotate90">flip</i>
      </button>
      <button class="Button Button--s" @click="grayScale" title="Grayscale">
        <i class="Icon">gradient</i>
      </button>
      <button class="Button Button--s" @click="sepia" title="Sepia">
        <i class="Icon">photo_filter</i>
      </button>
      <button class="Button Button--s" @click="undoCropper" :disabled="history.length === 0" title="Undo">
        <i class="Icon">undo</i>
      </button>
      <button class="Button Button--s" @click="resetCropper" :disabled="history.length === 0" title="Reset">
        <i class="Icon">replay</i>
      </button>
      <button class="Button Button--s" @click="setDpi(300)" :disabled="ppi === 300" title="300dpi">
        300ppi
      </button>
      <button class="Button Button--s" @click="setDpi(600)" :disabled="ppi === 600" title="600dpi">
        600ppi
      </button>
    </div>
    <div class="CropInfo" v-if="file">
      <small>Output: {{printSizeInCm}}cm @ {{ppi}}ppi ({{outputSize}}px)</small>
    </div>
    <div class="CropButtons" v-if="file">
      <div class="CropButtons-primary">
        <button class="Button Button--primary" @click="download">
          <i class="Icon">get_app</i>
          Download
        </button>
        <button class="Button Button--primary" @click="createMorble">
          <i class="Icon">fiber_manual_record</i>
          Create morble
        </button>
      </div>
      <button class="Button Button--s" @click="reset">
        Cancel
      </button>
      <p class="text-danger" v-if="isTooSmall">
        <small>Warning: cropped image resolution too small for {{ppi}}ppi print</small>
      </p>
    </div>
  </div>
</template>

<script>
//See: https://github.com/fengyuanchen/cropperjs
import Cropper from 'cropperjs';
import UserApi from '@/api/user.api';
import GalleryApi from '@/api/gallery.api';
import Morble from '@/models/morble.model';
import ModalCreateMorble from './modals/create-morble';

export default {
  data() {
    return {
      printSizeInCm: 3.5,
      ppi: 600,
      file: null,
      originalFile: null,
      isTooSmall: false,
      isFlippedX: false,
      isFlippedY: false,
      history: [],
    };
  },

  computed: {
    printSizeInInches() {
      return this.printSizeInCm / 2.54;
    },

    outputSize() {
      return Math.ceil(this.ppi * this.printSizeInInches);
    },
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Crop',
      crumbs: [
        {
          title: 'Crop',
          route: {name: 'crop'},
        },
      ],
    });
  },

  methods: {
    setFile(file) {
      this.file = file;

      this.$nextTick(function() {
        if (file) {
          this.setupImage();
        }
      });
    },

    reset() {
      this.file = null;
      this.cropper = null;
    },

    setupImage() {
      const reader = new FileReader();
      const { image } = this.$refs;

      //Create placeholder image to keep original file
      this.originalImage = new Image();

      reader.addEventListener('load', () => {
        image.src = reader.result;
        this.originalImage.src = reader.result;
        this.setupCropper();
      }, false);

      reader.readAsDataURL(this.file);
    },

    setupCropper() {
      const {image, preview} = this.$refs;

      this.cropper = new Cropper(image, {
        viewMode: 1,
        aspectRatio: 1,
        autoCropArea: 1,
        preview,
        checkOrientation: true,
        background: false,
        modal: false,
        center: false,
        guides: true,
        autoCrop: true,
        scalable: true,
        zoomable: false,
        rotatable: true,
        movable: false,
        crop: event => this.checkSize(event.detail.width),
        cropstart: () => {
          this.history.push({ action: 'crop', data: this.cropper.getCropBoxData() });
        },
      });
    },

    rotateLeft() {
      this.cropper.rotate(-90);
      this.history.push({ action: 'rotateLeft' });
    },

    rotateRight() {
      this.cropper.rotate(90);
      this.history.push({ action: 'rotateRight' });
    },

    flipX() {
      this.isFlippedX = !this.isFlippedX;
      this.cropper.scaleX(this.isFlippedX ? -1 : 1);
      this.history.push({ action: 'flipX' });
    },

    flipY() {
      this.isFlippedY = !this.isFlippedY;
      this.cropper.scaleY(this.isFlippedY ? -1 : 1);
      this.history.push({ action: 'flipY' });
    },

    createImageCopy() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      //Get image and image data
      const {image} = this.$refs;
      const data = this.cropper.getImageData();
      const {naturalWidth: width, naturalHeight: height} = data;

      //Set canvas size and draw image onto canvas
      Object.assign(canvas, {width, height});
      ctx.drawImage(image, 0, 0, width, height);

      //Return canvas and context
      return [canvas, ctx];
    },

    /**
     * Replace image in cropper
     */
    replaceImage(canvas, hasSameSize = true) {
      const dataUrl = canvas.toDataURL('image/jpeg');
      this.cropper.replace(dataUrl, hasSameSize);
    },

    resetCropper() {
      this.cropper.reset();
      this.cropper.replace(this.originalImage.src, true);
      this.history = [];
    },

    grayScale() {

      //Create image copy and get pixel data
      const [canvas, ctx] = this.createImageCopy();
      const [originalCanvas] = this.createImageCopy();
      const {width, height} = canvas;
      const pixels = ctx.getImageData(0, 0, width, height);

      //Manipulate to black and white
      for (let y = 0; y < pixels.height; y++) {
        for (let x = 0; x < pixels.width; x++) {
          let i = (y * 4) * pixels.width + x * 4;
          let avg = (pixels.data[i] + pixels.data[i + 1] + pixels.data[i + 2]) / 3;
          pixels.data[i] = avg;
          pixels.data[i + 1] = avg;
          pixels.data[i + 2] = avg;
        }
      }

      //Put image data back and replace image
      ctx.putImageData(pixels, 0, 0, 0, 0, pixels.width, pixels.height);
      this.replaceImage(canvas);
      this.history.push({ action: 'grayScale', data: originalCanvas });
    },

    sepia() {

      //Create image copy and get pixel data
      const [canvas, ctx] = this.createImageCopy();
      const [originalCanvas] = this.createImageCopy();
      const {width, height} = canvas;
      const pixels = ctx.getImageData(0, 0, width, height);

      // Sepia calcs from https://dyclassroom.com/image-processing-project/how-to-convert-a-color-image-into-sepia-image
      const tr = (r, g, b) => (r * 0.393) + (g * 0.769) + (b * 0.189);
      const tg = (r, g, b) => (r * 0.349) + (g * 0.686) + (b * 0.168);
      const tb = (r, g, b) => (r * 0.272) + (g * 0.534) + (b * 0.131);

      for (let i = 0; i < pixels.data.length; i += 4) {
        const newr = tr(pixels.data[i], pixels.data[i + 1], pixels.data[i + 2]);
        const newg = tg(pixels.data[i], pixels.data[i + 1], pixels.data[i + 2]);
        const newb = tb(pixels.data[i], pixels.data[i + 1], pixels.data[i + 2]);
        pixels.data[i] = newr;
        pixels.data[i + 1] = newg;
        pixels.data[i + 2] = newb;
      }

      //Put image data back and replace image
      ctx.putImageData(pixels, 0, 0, 0, 0, width, height);
      this.replaceImage(canvas);
      this.history.push({ action: 'sepia', data: originalCanvas });
    },

    setDpi(ppi) {
      this.ppi = ppi;
      this.checkSize();
      this.history.push({ action: `setDpi-${ppi}` });
    },

    /**
     * Check resolution
     */
    checkSize(size) {
      this.isTooSmall = (size < this.outputSize);
    },

    /**
     * Get cropped image canvas
     */
    getCropCanvas() {
      const {outputSize} = this;
      const canvas = this.cropper.getCroppedCanvas({
        width: outputSize,
        height: outputSize,
      });

      const cw = canvas.width;
      const ch = canvas.height;

      //Create new canvas for cropping
      const crop = document.createElement('canvas');
      const ctx = crop.getContext('2d');
      crop.width = cw;
      crop.height = ch;

      //Clip circle
      ctx.beginPath();
      ctx.arc(cw / 2, ch / 2, ch / 2, 0, Math.PI * 2, true);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(canvas, 0, 0, cw, ch);

      //Return canvas
      return crop;
    },

    /**
     * Download cropped image
     */
    download() {

      //Convert to data URL and create link
      const canvas = this.getCropCanvas();
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');

      //Set properties
      link.download = `crop`;
      link.href = dataUrl;

      //Add to body, click it, and remove again
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    /**
     * Create morble from cropped image
     */
    async createMorble() {

      //Mark as loading
      this.isPreparingCreateMorble = true;

      //Load users and create new morble
      const users = await UserApi.list({fields: 'name'});
      const morble = new Morble();
      const canvas = this.getCropCanvas();

      //Create image blob in the background
      canvas.toBlob(async (blob) => {
        this.blob = blob;
      }, 'image/png');

      const onCreate = async (model) => {
        try {

          //Get data
          const {user, name} = model;

          //Create gallery and append ID to model data
          const gallery = await GalleryApi.create({user, name});
          model.gallery = gallery.id;

          await morble.save(model);

          //Make image data
          const {blob} = this;
          const formData = new FormData();
          formData.append('image', blob);

          //Upload image
          await morble.uploadImage(formData);

          //Call created handler
          this.onCreated(morble);
        }
        catch (error) {
          this.$err.process(error);
        }
      };

      this.$modal.show(ModalCreateMorble, {
        morble, users, onCreate,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    onCreated(morble) {
      this.$notice.show('Morble created');
      this.$router.push({
        name: 'gallery.artefacts',
        params: {id: morble.gallery.id},
      });
    },

    undoCropper() {
      const { action, data } = this.history.pop();
      if (!action) {
        return;
      }

      switch (action) {
        case 'flipX':
        case 'flipY':
          this[action]();
          break;
        case 'rotateRight':
          this.rotateLeft();
          break;
        case 'rotateLeft':
          this.rotateRight();
          break;
        case 'setDpi-300':
          this.setDpi(600);
          break;
        case 'setDpi-600':
          this.setDpi(300);
          break;
        case 'grayScale':
        case 'sepia':
          this.replaceImage(data);
          break;
        case 'crop':
          this.cropper.setCropBoxData(data);
          break;
      }

      // all other actions add a new entry to the history, which should be removed
      if (!['grayScale', 'sepia', 'crop'].includes(action)) {
        this.history.pop();
      }
    },
  },
};
</script>

<style lang="scss">
@import 'node_modules/cropperjs/dist/cropper';

.Crop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacingLarge;
}

.CropImageContainer {
  width: 20rem;
  height: 20rem;
}

.CropImage {
  max-width: 100%;
}

.CropPreview {
  border-radius: 50%;
  overflow: hidden;
  width: 20rem;
  height: 20rem;
}

.CropPreviewIcon {
  font-size: 8rem !important;
  color: $colorTextMuted;
  margin: 0 3rem;
}

.CropOptions {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CropInfo {
  margin-top: 3rem;
  text-align: center;
}

.CropButtons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Button--s {
    margin-top: $spacing;
    margin-left: 0 !important;
  }
}

.CropButtons-primary {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cropper-view-box {
  border-radius: 100%;
  outline-width: 2px;
}
.cropper-line {
  background: none;
}
.cropper-point.point-se {
  height: 10px;
  width: 10px;
}
</style>
