<template>
  <div>
    <app-header />
    <div class="Container">
      <div class="Loader" v-if="isLoading">
        <spinner />
      </div>
      <router-view v-else />
    </div>
    <notices-container />
    <app-footer />
  </div>
</template>

<script>
import {mapActions} from 'vuex';
import auth from '@/services/auth';

//Components
import AppHeader from './header/header';
import AppFooter from './footer/footer';

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async created() {

    //Initialize auth service
    auth.init();

    //Load session user
    try {
      await this.loadUser();
    }
    catch (error) {
      this.$err.process(error);
    }
    finally {
      this.isLoading = false;
    }
  },

  methods: {

    /**
     * Map session store actions to use as component methods
     */
    ...mapActions('session', [
      'loadUser',
    ]),
  },
};
</script>
