import { SET_USER } from './types';

/**
 * Mutations for the Session state
 */
export default {

  /**
   * Set logged in user
   */
  [SET_USER](state, user) {
    state.user = user;
  },
};
