
/**
 * Base configuration
 */
export default {
  cms: {
    title: 'Morble CMS',
  },

  qrCode: {
    version: 2,
    errorCorrectionLevel: 'L',
    maskPattern: 7,
    margin: 1,
    ppis: [300, 600],
  },

  storage: {
    prefix: 'morble-cms',
  },

  auth: {
    clientId: 'morble.cms',
    homeRoute: 'dashboard',
    loginRoute: 'login',
    allowedUnauthenticated: [
      'login', 'oAuthCallback',
    ],
  },

  sentry: {
    dsn: '',
    tags: {
      source: 'cms',
    },
    ignoreErrors: [],
  },

  errors: {
    log: false,
  },

  inventory: {
    levels: {
      green: 5,
      orange: 3,
      red: 2,
    },
  },
};
