<template>
  <div class="Page">
    <page-header
      type="Activity log"
      :total-items="totalItems"
      :show-search="true"
      @search="search($event.query)"
    >
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div v-if="isLoading">Loading...</div>
    <div v-else-if="!hasItems">No logs yet</div>
    <div v-else>
      <div class="Table">
        <div class="Table-header">
          <div class="Table-col">Date/time</div>
          <div class="Table-col">User &amp; event</div>
          <div class="Table-col">Target</div>
          <div class="Table-col">Origin</div>
        </div>
        <div
          v-for="auditLog in auditLogs"
          :key="auditLog.id"
          class="Table-row"
        >
          <div class="Table-col">
            {{auditLog.date | moment('lll')}}
          </div>
          <div class="Table-col">
            <router-link v-if="auditLog.user" :to="userLink(auditLog)">
              {{auditLog.user.name}}
            </router-link>
            {{auditLog.action}}
          </div>
          <div class="Table-col">
            <div v-if="auditLog.item && auditLog.item.model">
              <router-link v-if="auditLog.item.model=='User'" :to="'/user/edit/' + auditLog.item._id">{{auditLog.item.name}}</router-link>
              <router-link v-if="auditLog.item.model=='Morble'" :to="'/morble/edit/' + auditLog.item._id">{{auditLog.item.name}}</router-link>
              <div v-else>{{auditLog.item.name}}</div>
            </div>
          </div>
          <div class="Table-col">
            {{auditLog.origin}}
          </div>
        </div>
      </div>

      <pagination
        :show-all="totalItems < 500"
        :page="page"
        :num-pages="numPages"
        @change="loadPage($event.page)"
      ></pagination>
    </div>
  </div>
</template>

<script>
import AuditLogApi from '@/api/audit-log.api';

export default {
  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,

      auditLogs: [],

      isLoading: false,
    };
  },

  computed: {
    hasItems() {
      return this.auditLogs.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  beforeMount() {
    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Activity Log',
      crumbs: [
        {
          title: 'Activity Log',
          route: {name: 'activity-log'},
        },
      ],
    });
  },

  created() {
    this.loadAuditLogs();
  },

  methods: {
    search(q) {
      this.loadAuditLogs({q});
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.page = page;
      this.loadAuditLogs();
    },

    async loadAuditLogs(extra) {
      this.isLoading = true;

      //Get filter and query data
      const filter = this.makeFilter(extra);

      await AuditLogApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    makeFilter(extra) {
      const filter = {};
      const {page, itemsPerPage} = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processData(data) {
      const {meta, auditLogs} = data;
      this.auditLogs = auditLogs;
      this.totalItems = meta.total;
    },

    userLink(auditLog) {
      return auditLog.item ? `/user/edit/${auditLog.item._id}` : null;
    },
  },
};
</script>

<style scoped lang="scss">
.Table-col--lastOnline {
  flex: 0 0 9rem;
}
.Table-col--roles {
  flex: 0 0 8rem;
  span + span:before {
    content: ", ";
  }
}
</style>
