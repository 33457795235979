<template>
  <modal-edit
    type="catalogue item"
    :model="model"
    :is-edit="isEdit"
    :on-save="onSave"
    :on-remove="onRemove"
    @close="$emit('close')"
    @cancel="$emit('close')"
  >
    <template v-slot:default>
      <div class="Form">
        <div class="Group">
          <label class="Label">Description</label>
          <input
            class="Input"
            type="text"
            v-model="model.description"
          >
        </div>

        <div class="Group" v-if="model.usedInCount">
          <label class="Label">Used by</label>

          {{ model.usedInCount }} morbles
        </div>
      </div>
    </template>
    <template v-slot:secondaryButtons>
      <button
        v-if="!model.usedInCount || model.usedInCount === 0"
        class="Button Button--danger"
        @click="remove"
        :disabled="isRemoving"
      >
        Remove
        <spinner />
      </button>
      <button
        v-else-if="model.available"
        class="Button Button--danger"
        @click="onDiscontinue"
      >
        Mark discontinued
      </button>
      <button
        v-else
        class="Button"
        @click="onMarkAvailable"
      >
        Mark available
      </button>
    </template>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modal-edit';

export default {
  components: { ModalEdit },

  data() {
    return {
      model: {
        description: null,
        usedInCount: 0,
        available: true,
      },
      isRemoving: false,
    };
  },

  props: {
    catalogueItem: { type: Object },
    onSave: { type: Function },
    onRemove: { type: Function },
    onDiscontinue: { type: Function },
    onMarkAvailable: { type: Function },
    isEdit: { type: Boolean },
  },

  emits: ['close'],

  created() {
    //Create model subset
    this.model = this.catalogueItem.extract(Object.keys(this.model));
  },

  methods: {
    remove() {
      this.isRemoving = true;
      this.onRemove().then(() => {
        this.isRemoving = false;
      });
    },
  },
};
</script>
