<template>
  <div v-if="batches.length" class="Table">
    <h3>
      <i class="Icon" v-if="icon">{{icon}}</i>
      {{heading}}
    </h3>

    <div class="Table-header">
      <div class="Table-col">Name</div>
      <div class="Table-col">Type</div>
      <div class="Table-col">Created</div>
      <div class="Table-col">Created by</div>
      <div class="Table-col">Morbles</div>
      <div class="Table-col Table-col--dotMenu">&nbsp;</div>
    </div>

    <div
      v-for="batch in batches"
      :key="batch.id"
      class="Table-row is-clickable"
    >
      <div class="Table-col" @click="$emit('process', batch)">{{batch.name}}</div>
      <div class="Table-col" @click="$emit('process', batch)">{{batch.type}}</div>
      <div class="Table-col" @click="$emit('process', batch)">{{batch.createdAt | moment('lll')}}</div>
      <div class="Table-col" @click="$emit('process', batch)">{{batch.createdBy ? batch.createdBy.name : null }}</div>
      <div class="Table-col" @click="$emit('process', batch)">{{batch.morbles.length}}</div>
      <div class="Table-col Table-col--dotMenu">
        <dot-menu>
          <li><a class="default" @click="$emit('process', batch)">Details</a></li>
          <li><a @click="$emit('remove', batch)">Delete</a></li>
        </dot-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    batches: {
      type: Array,
      required: true,
    },
    heading: { type: String },
    icon: { type: String },
  },

  emits: ['process', 'remove'],
};
</script>

<style scoped>
.Table {
  margin: 0 0 2.5rem;
}
.Table-header {
  margin-top: 1rem;
}
</style>
