<template>
  <div class="Page">
    <page-header
      type="morble"
      :total-items="totalItems"
      :show-search="true"
      @search="search($event.query)"
    >
      <template slot="pageActions">
        <button
          class="Button Button--s Button--primary"
          @click="add"
          :disabled="isLoading"
        >
          <i class="Icon">add</i> Create custom morble
        </button>
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div v-if="isLoading">Loading... <spinner class="Spinner--inline" /></div>
    <div v-else-if="!hasItems">No morbles yet</div>

    <div v-else>
      <morble-table
        :morbles="morblesWithOwner"
        heading="Owned morbles"
        @edit="edit"
        @duplicate="duplicate"
        @remove="remove"
        @qr="qr"
      />

      <morble-table
        :morbles="morblesWithoutOwner"
        heading="Morbles without an owner"
        @edit="edit"
        @duplicate="duplicate"
        @remove="remove"
        @qr="qr"
      />

      <pagination
        :show-all="totalItems < 500"
        :page="page"
        :num-pages="numPages"
        @change="loadPage($event.page)"
      ></pagination>
    </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import GalleryApi from '@/api/gallery.api';
import MorbleApi from '@/api/morble.api';
import Morble from '@/models/morble.model';
import ModalChoosePresetImage from '@/components/shared/modal-choose-preset-image';
import ModalEditMorble from './modals/edit';
import ModalDuplicateMorble from './modals/duplicate';
import ModalRemoveMorble from './modals/remove';
import QRCode from './modals/qr-code';
import MorbleTable from './morble-table';
import CropModal from '@/components/shared/crop-modal';

export default {
  components: { MorbleTable },

  props: { id: String },

  data() {
    return {
      page: 1,
      itemsPerPage: 20,
      totalItems: 0,
      morbles: [],
      isLoading: false,
    };
  },

  computed: {
    hasItems() {
      return this.morbles.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    morblesWithOwner() {
      return this.morbles.filter(morble => morble.user);
    },

    morblesWithoutOwner() {
      return this.morbles.filter(morble => !morble.user);
    },
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Morbles',
      crumbs: [
        {
          title: 'Morbles',
          route: {name: 'morble'},
        },
      ],
    });
  },

  async created() {
    await this.loadUsersList();
    await this.loadGalleriesList();
    await this.loadMorbles();
    await this.processAction();
  },

  methods: {
    async loadMorbles(extra) {
      this.isLoading = true;

      const filter = this.makeFilter(extra);

      await MorbleApi
        .query(filter)
        .then(({ meta: { total }, morbles }) => {
          this.morbles = morbles;
          this.totalItems = total;
        })
        .finally(() => this.isLoading = false);
    },

    async loadUsersList() {
      const users = await UserApi.list({ fields: 'name' });
      this.users = users;
    },

    async loadGalleriesList() {
      await GalleryApi
        .list({fields: 'name'})
        .then(galleries => this.galleries = galleries);
    },

    add() {
      const morble = new Morble({ type: 'custom', createGallery: true, stage: 'new' });
      this.edit(morble, false);
    },

    edit(morble, isEdit = true) {
      const { users, galleries } = this;

      const onSave = model => morble
        .save(model)
        .then(() => this.onSaved(morble, isEdit));

      const onRemove = (isEdit = false) => morble
        .remove()
        .then(() => isEdit ? this.onRemoved() : null);

      this.$modal.show(ModalEditMorble, {
        morble, users, galleries, isEdit, onSave, onRemove,
        editImage: this.editImage,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    editImage(morble, cb) {
      const onUpload = async data => {
        // no morble created yet
        if (!morble.id) {
          // don't create gallery until user chooses Save
          morble.createGallery = false;
          await morble.save();
        }

        return morble.uploadImage(data)
          .then(updatedMorble => {
            this.$notice.show('Morble image updated');
            cb(updatedMorble);
          });
      };

      this.$modal.show(CropModal, { onUpload }, {
        clickToClose: false,
        height: 'auto',
        width: 750,
        scrollable: true,
      });
    },

    duplicate(morble) {
      const onDuplicate = num => morble
        .duplicate(num)
        .then(this.onDuplicated);

      this.$modal.show(ModalDuplicateMorble, {
        morble,
        onDuplicate,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    remove(morble) {
      const onRemove = () => morble
        .remove()
        .then(this.onRemoved);

      this.$modal.show(ModalRemoveMorble, {
        morble,
        onRemove,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    choosePresetImage(morble, cb) {
      const onChoose = async presetImage => {
        const { id } = presetImage;
        const updatedMorble = await morble.choosePresetImage(id);
        this.loadMorbles();
        this.$notice.show('Morble image updated');
        cb(updatedMorble);
      };

      this.$modal.show(ModalChoosePresetImage, {
        morble, onChoose,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    search(q) {
      this.loadMorbles({q});
    },

    async onSaved(morble, isEdit) {
      const { orphanedGallery } = morble;

      this.$notice.show('Morble saved');

      if (orphanedGallery) {
        const gallery = await GalleryApi.findById(orphanedGallery);
        if (confirm(`The old gallery '${gallery.name}' is no longer attached to any Morbles. Okay to delete?`)) {
          await gallery.remove();
          alert('Gallery deleted');
        }
      }

      //Reload morbles if we added a new one
      if (!isEdit) {
        this.loadMorbles();
        this.loadGalleriesList();
      }
    },

    onDuplicated() {
      this.$notice.show('Morble duplicated');
      this.loadMorbles();
    },

    onRemoved() {
      this.$notice.show('Morble removed');
      this.loadMorbles();
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.page = page;
      this.loadMorbles();
    },

    makeFilter(extra) {
      const filter = {};
      const {page, itemsPerPage} = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      return filter;
    },

    processAction() {
      const { action, id } = this.$route.params;

      if (action === 'edit' && id) {
        const morble = this.morbles.find(morble => morble.id === id);
        if (morble) {
          this.edit(morble);
        }
      }
    },

    qr(morble) {
      const { code, url } = morble;

      //Open modal
      this.$modal.show(QRCode, {
        code, url,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },
  },
};
</script>
