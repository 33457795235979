import merge from 'deepmerge';
import base from './base';

const basePath = process.env.LOCAL_IP || 'localhost';

/**
 * Development environment configuration
 */
export default merge.all([base, {
  cms: {
    baseUrl: `http://${basePath}:8082`,
  },

  api: {
    baseUrl: `http://${basePath}:8081/api`,
  },

  app: {
    baseUrl: `http://${basePath}:8080`,
  },

  errors: {
    log: true,
  },
}]);
