<template>
  <div class="CheckBoxGroup">
    <label
      class="CheckBox"
      v-for="(option, index) in options"
      :key="option[bindKey]"
      :class="{checked: isChecked(option, index), disabled: isDisabled}"
      @click="toggle(option, index)"
    >{{getLabelValue(option)}}</label>
  </div>
</template>

<script>

export default {
  props: {
    model: {
      type: [String, Number, Object],
      default: () => null,
      validator: prop => (
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        typeof prop === 'object' ||
        prop === null
      ),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: () => 'label',
    },
    trackBy: {
      type: String,
      default: () => 'value',
    },
    asObject: {
      type: Boolean,
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    bindKey: {
      type: String,
      default: () => 'value',
    },
  },

  emits: ['change'],

  created() {
    this.onChanges();
  },

  watch: {
    model() {
      this.onChanges();
    },

    options() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {

    },

    /**
     * Toggle an option
     */
    toggle(option, index) {

      //Get data
      const {asObject, model} = this;

      //Initialize value for model array
      let value = [];
      if (Array.isArray(model)) {
        value = model.map(item => item);
      }

      //Check if currently checked and get option value
      const isChecked = this.isChecked(option, index);
      const optionValue = this.getTrackingValue(option, index);

      //If checked, remove from target model, otherwise add
      if (isChecked) {
        const i = value.findIndex(model => {
          const modelValue = this.getTrackingValue(model, model);
          return (modelValue === optionValue);
        });
        value.splice(i, 1);
      }
      else {
        value.push(asObject ? option : optionValue);
      }

      //Emit change
      this.$emit('change', {value});
    },

    /**
     * Check if an option is checked
     */
    isChecked(option, index) {

      //Get data
      const {model} = this;

      //Nothing selected
      if (!Array.isArray(model) || model.length === 0) {
        return false;
      }

      //Get option value
      const optionValue = this.getTrackingValue(option, index);

      //See if present in model values
      return model.some(model => {
        const modelValue = this.getTrackingValue(model, model);
        return (modelValue === optionValue);
      });
    },

    /**
     * Helper to get the tracking value of an option
     */
    getTrackingValue(option, index) {

      //Get data
      const {trackBy} = this;

      //Tracking by index?
      if (trackBy === '$index') {
        return index;
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have tracking property
      if (!trackBy) {
        throw new Error('Missing track-by property for check boxes');
      }

      //Validate property
      if (typeof option[trackBy] === 'undefined') {
        throw new Error(`Unknown property '${trackBy}' for check boxes tracking`);
      }

      //Return the property
      return option[trackBy];
    },

    /**
     * Get label value of an option
     */
    getLabelValue(option) {

      //Get data
      const {labelBy, nullLabel} = this;

      //Null value?
      if (option === null || typeof option === 'undefined') {
        return nullLabel;
      }

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have label property
      if (!labelBy) {
        throw new Error('Missing label-by property for check boxes');
      }

      //Validate property
      if (typeof option[labelBy] === 'undefined') {
        throw new Error(`Unknown property '${labelBy}' for check boxes label`);
      }

      //Return the property
      return option[labelBy];
    },
  },
};
</script>

<style lang="scss">

</style>
