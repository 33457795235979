<template>
  <div class="Modal">
    <div class="ModalHeader">
      <h1>QR Code</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <p class="text-center">
        <strong>{{url}}</strong>
        <br><small class="text-muted">(click QR code to download image)</small>
      </p>
      <hr>
      <div class="QRCodes">
        <canvas
          v-for="width in widths"
          :key="width"
          :id="'QRCode--' + width"
          @click="download(width)"
        ></canvas>
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :show-confirm="false"
        label-cancel="Close"
        @cancel="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';

export default {
  data() {
    return {
      //NOTE: QR code printable area 1x1cm = 0.39370 inch
      widths: this.$cfg.qrCode.ppis.map(ppi => Math.floor(ppi * 0.39370)),
    };
  },

  props: {
    code: { type: String },
    url: { type: String },
  },

  emits: ['close'],

  /**
   * Generate QR code on mount of component
   */
  mounted() {
    this.generate();
  },

  methods: {

    /**
     * Get config for QR code generation
     */
    getConfig(options) {

      //Get config from app
      const {
        version, errorCorrectionLevel, maskPattern, margin,
      } = this.$cfg.qrCode;

      //Return base config
      return Object.assign({
        version,
        maskPattern,
        errorCorrectionLevel,
        margin,
      }, options);
    },

    /**
     * Generate QR code
     */
    async generate() {

      //Get URL and widths
      const {url, widths} = this;

      //Generate canvases and QR codes
      try {
        for (const width of widths) {
          const canvas = document.getElementById(`QRCode--${width}`);
          const config = this.getConfig({width});
          await QRCode.toCanvas(canvas, url, config);
        }
      }
      catch (error) {
        this.$err.process(error);
      }
    },

    /**
     * Download QR code
     */
    async download(width) {

      //Get URL
      const {url, code} = this;
      const config = this.getConfig({
        width,
        type: 'image/png',
        rendererOpts: {
          quality: 1.0,
        },
      });

      //Download
      try {

        //Get data URL and create link
        const dataUrl = await QRCode.toDataURL(url, config);
        const link = document.createElement('a');

        //Set properties
        link.download = `${code}-${width}x${width}`;
        link.href = dataUrl;

        //Add to body, click it, and remove again
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      catch (error) {
        this.$err.process(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.QRCodes {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
canvas {
  cursor: pointer;
}
</style>
