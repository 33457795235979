import http from '@/services/http';
import User from '@/models/user.model';

export default {

  /**
	 * Get data for logged in user
	 */
  me() {
    return http
      .get('/user/me');
  },

  query(filter) {
    return http
      .get('/user', filter)
      .then(data => User.createFrom(data, null, 'users'));
  },

  list(filter) {
    return http
      .get('/user/list', filter)
      .then(users => User.createFrom(users));
  },

  register(user) {
    return http
      .post('/user/register', {user});
  },

  create(data) {
    return http
      .post('/user', data);
  },

  update(id, data) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http.put(`/user/${id}`, data);
  },

  remove(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http.delete(`/user/${id}`);
  },

};
