import moment from '@/services/moment';
import { dateFormatStandard } from '@/constants/date-formats';

export default function(date, format, relative) {
  if (!moment.isMoment(date)) {
    if (!date) {
      return '';
    }
    date = moment(date);
    if (!date.isValid()) {
      return '';
    }
  }
  if (relative) {
    let now = moment();
    if (now.isSame(date, 'day')) {
      return 'Today';
    }
    if (now.add(1, 'day').isSame(date, 'day')) {
      return 'Tomorrow';
    }
  }
  return date.format(format || dateFormatStandard);
}
