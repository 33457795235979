<template>
  <div class="Modal">
    <div class="ModalHeader">
      <h1>Choose preset image</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>

    <div class="ModalBody">
      <div v-if="isLoading">
        Loading...
        <spinner class="Spinner--inline" />
      </div>

      <div v-else class="CataloguePhotos">
        <div v-for="item in catalogue" :key="item.id" class="wrapper">
          <div class="CataloguePhotoSquare">
            <div
              class="MorbleCoverImage"
              :style="`background-image: url(${item.thumbUrl})`"
              @click="choose(item)"
            />
          </div>
          <div class="details">
            <div>{{item.description}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="ModalFooter">
      <button-bar
        :showConfirm="false"
        @cancel="$emit('close')"
      ></button-bar>
    </div>
  </div>
</template>

<script>
import CatalogueItemApi from '@/api/catalogue-item.api';

export default {
  data() {
    return {
      catalogue: [],
      isLoading: true,
      model: {
        image: null,
        description: '',
      },
    };
  },

  props: {
    morble: { type: Object },
    onChoose: { type: Function },
  },

  emits: ['close'],

  async created() {
    //Create model subset
    this.model = this.morble.extract(Object.keys(this.model));
    this.catalogue = await CatalogueItemApi.query({ available: true });
    this.isLoading = false;
  },

  methods: {
    choose(item) {
      this.onChoose(item);
      this.$emit('close');
    },
  },
};

</script>

<style lang="scss" scoped>

.CataloguePhotos {
  display: flex;
  flex-wrap: wrap;
}

.wrapper {
  width: 10rem;
}

.CataloguePhotoSquare {
  margin-right: $spacingMedium;
  margin-bottom: $spacingMedium;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.details {
  text-align: center;
}

</style>
