<template>
  <div class="Modal">
    <div class="ModalHeader">
      <h1>Create Morble</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <div class="Form">
        <div class="Group">
          <label class="Label">User</label>
          <select-box
            :model="model.user"
            :options="users"
            label-by="name"
            track-by="id"
            @change="model.user = $event.value"
          />
        </div>
        <div class="Group">
          <label class="Label">Name</label>
          <input
            class="Input"
            type="text"
            v-model="model.name"
          >
        </div>
        <div class="Group">
          <label class="Label">Tags</label>
          <tags-input
            :model="model.tags"
            @change="model.tags = $event.value"
          />
        </div>
        <div class="Group">
          <check-box
            :model="model.isPublic"
            @change="model.isPublic = $event.value"
          >Morble is public (visible by anyone, editable by no-one)</check-box>
        </div>
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isCreating"
        label-confirm="Create"
        @cancel="$emit('close')"
        @confirm="create()"
      ></button-bar>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      model: {
        name: null,
        user: null,
        tags: null,
        gallery: null,
        isPublic: null,
      },
      isError: false,
      isCreating: false,
    };
  },

  props: {
    morble: { type: Object },
    users: { type: Array },
    onCreate: { type: Function },
  },

  emits: ['close'],

  created() {
    //Create model subset
    this.model = this.morble.extract(Object.keys(this.model));
  },

  methods: {
    create() {
      const { model } = this;

      //Toggle flags
      this.isCreating = true;
      this.isError = false;

      this
        .onCreate(model)
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isCreating = false);
    },
  },
};
</script>
