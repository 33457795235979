import http from '@/services/http';
import AuditLog from '@/models/audit-log.model';

export default {
  query(filter) {
    return http
      .get('/audit-log', filter)
      .then(data => AuditLog.createFrom(data, null, 'users'));
  },
};
