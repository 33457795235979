<template>
  <div class="Page">
    <page-header
      plainTitle="Manufacturing"
    />

    <div v-if="isLoading">Loading...</div>

    <div v-else>
      <button
        v-if="availableMorbles.length"
        class="Button Button--s"
        @click="add('custom')">
        <i class="Icon">add</i> New custom morble batch
      </button>

      <button
        class="Button Button--s"
        @click="add('preset')"
      >
        <i class="Icon">workspaces</i> New preset morble batch
      </button>

      <div>
        <small>There are {{availableMorbles.length}} custom morbles available to be added to batches.</small>
      </div>

      <batch-table
        heading="Ready for export &amp; manufacture"
        icon="blur_circular"
        :batches="newBatches"
        @process="process"
        @remove="remove"
      />

      <batch-table
        heading="In manufacturing"
        icon="precision_manufacturing"
        :batches="manufacturingBatches"
        @process="process"
        @remove="remove"
      />

      <batch-table
        heading="Manufactured"
        icon="inventory"
        :batches="manufacturedBatches"
        @process="process"
        @remove="remove"
      />
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/shared/page-header';
import Batch from '@/models/batch.model';
import BatchApi from '@/api/batch.api';
import MorbleApi from '@/api/morble.api';
import ModalEdit from './modals/edit';
import BatchTable from './batch-table';

export default {
  components: { PageHeader, BatchTable },

  data() {
    return {
      isLoading: true,
      batches: [],
      availableMorbles: [],
    };
  },

  computed: {
    newBatches() {
      return this.batches.filter(({ manufacturing, manufactured, active }) => !manufacturing && !manufactured && !active);
    },

    manufacturingBatches() {
      return this.batches.filter(({ manufacturing }) => manufacturing);
    },

    manufacturedBatches() {
      return this.batches.filter(({ manufactured }) => manufactured);
    },
  },

  async created() {
    await this.loadBatches();
  },

  methods: {
    async loadBatches() {
      this.batches = await BatchApi.query();
      const res = await MorbleApi.query({ noBatch: true });
      this.availableMorbles = res.morbles;
      this.isLoading = false;
    },

    add(type) {
      const batch = new Batch({ morbles: [] });
      this.process(batch, type, true);
    },

    async process(bareBatch, type, isNew) {
      const { availableMorbles } = this;

      const batch = bareBatch.id ? await BatchApi.findById(bareBatch.id) : bareBatch;

      const onSave = model => batch
        .save(model, type)
        .then(this.onSaved);

      if (!type) {
        type = batch.type;
      }

      this.$modal.show(ModalEdit, {
        type,
        batch,
        availableMorbles,
        isNew,
        onSave,
        reloadOverview: this.loadBatches,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    onSaved() {
      this.$notice.show('Batch saved');
      this.loadBatches();
    },

    async remove(batch) {
      if (!confirm(`Are you sure you want to remove this batch? The batch's morbles' lifecycle status will be reset.`)) {
        return;
      }

      await batch.remove();
      this.loadBatches();
    },
  },
};

</script>
