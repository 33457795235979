<template>
  <div class="Page">
    <menu class="Dashboard">
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/user">
          <i class="Icon">person</i>
          <span>Users</span>
        </router-link>
      </li>
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/morble">
          <i class="Icon">fiber_manual_record</i>
          <span>Morbles</span>
        </router-link>
      </li>
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/gallery">
          <i class="Icon">photo</i>
          <span>Galleries</span>
        </router-link>
      </li>
      <!-- <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/crop">
          <i class="Icon">crop</i>
          <span>Crop</span>
        </router-link>
      </li> -->
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/activity">
          <i class="Icon">reorder</i>
          <span>Activity log</span>
        </router-link>
      </li>
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/catalogue">
          <i class="Icon">view_list</i>
          <span>Preset Catalogue</span>
        </router-link>
      </li>
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/manufacturing">
          <i class="Icon">precision_manufacturing</i>
          <span>Manufacturing</span>
        </router-link>
      </li>
      <li class="Dashboard-item">
        <router-link class="Dashboard-link" to="/inventory">
          <i class="Icon">inventory</i>
          <span>Inventory</span>
        </router-link>
      </li>
    </menu>
  </div>
</template>

<script>
export default {
  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Dashboard',
      crumbs: [
        {
          title: 'Dashboard',
          route: {name: 'dashboard'},
        },
      ],
    });
  },
};
</script>

<style lang="scss">
.Dashboard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: $spacingLarge;
}
.Dashboard-item {
  flex: 0 0 33.33%;
}
.Dashboard-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $colorText;
  font-weight: $fontWeightBold;
  padding: $spacingLarge;
  transition: background-color .1s ease-in;
  .Icon {
    font-size: 60px;
    color: $colorTextMuted;
    margin-bottom: $spacing;
    transition: color .1s ease-in;
  }
  &:hover {
    background: $colorLighterGray;
    .Icon {
      color: $colorPrimary;
    }
  }
}
</style>
