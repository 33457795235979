<template>
  <div class="SelectBox">
    <div
      class="InputWrapper is-clickable"
      @click="toggleOptions"
    >
      <div class="Caret"
        @click.stop="toggleOptions"
      ></div>
      <input
        :disabled="disabled"
        readonly
        class="Input"
        type="text"
        v-model="selectedLabel"
      >
    </div>
    <ul class="SelectBox-options" v-if="isShowingOptions">
      <li
        v-if="isNullable || !hasOptions"
        :class="{selected: isSelection(-1)}"
        @mouseover="setSelection(-1)"
        @click.prevent="confirmSelection(-1)"
      >{{nullLabel}}</li>
      <li
        v-for="(option, index) in options"
        :key="option[bindKey]"
        :class="{selected: isSelection(index)}"
        @mouseover="setSelection(index)"
        @click.prevent="confirmSelection(index)"
      >{{getLabelValue(option)}}</li>
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isShowingOptions: false,
      hasOptions: false,
      selectedLabel: '',
      selectionIndex: -1,
    };
  },

  props: {
    model: {
      type: [String, Number, Object],
      default: () => null,
      validator: prop => (
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        typeof prop === 'object' ||
        prop === null
      ),
    },
    options: {
      type: Array,
      default: () => [],
    },
    labelBy: {
      type: String,
      default: () => 'label',
    },
    trackBy: {
      type: String,
      default: () => 'value',
    },
    asObject: {
      type: Boolean,
      default: () => false,
    },
    isNullable: {
      type: Boolean,
      default: () => false,
    },
    nullValue: {
      type: null,
      default: () => null,
    },
    nullLabel: {
      type: String,
      default: () => '...',
    },
    bindKey: {
      type: String,
      default: () => 'value',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['change'],

  created() {

    //Create bound handler and find body
    this.boundClickHandler = this.documentClickHandler.bind(this);
    this.body = document.getElementsByTagName('body')[0];

    //Apply global click handler
    //NOTE: applied on body, so that it can prevent global document handlers
    this.body.addEventListener('click', this.boundClickHandler);

    //Call changes handler
    this.onChanges();
  },

  destroyed() {

    //Remove global listener
    this.body.removeEventListener('click', this.boundClickHandler);
  },

  watch: {
    model() {
      this.onChanges();
    },
    options() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {

      //Get data
      const {model, options} = this;
      const option = this.findOption(model, options);

      //Determine selected label and check if we have options
      this.selectedLabel = this.getLabelValue(option);
      this.hasOptions = options.length > 0;
    },

    showOptions() {
      this.isShowingOptions = true;
    },

    hideOptions() {
      this.isShowingOptions = false;
    },

    toggleOptions() {
      if (this.isShowingOptions) {
        this.hideOptions();
      }
      else {
        this.showOptions();
      }
    },

    /**
     * Select an option
     */
    selectOption(option, index) {
      this.hideOptions();

      //Get the new model value and call on change handler
      const value = this.getModelValue(option, index);
      this.$emit('change', {value, option, index});
    },

    /**
     * Check if given index is the selection index
     */
    isSelection(index) {
      return (this.selectionIndex === index);
    },

    /**
     * Set the selection index
     */
    setSelection(index) {
      this.selectionIndex = index;
    },

    /**
     * Confirm selection
     */
    confirmSelection(index) {

      //Get data
      const {isNullable, hasOptions, options, selectionIndex} = this;

      //If index not given, use current selection index
      if (typeof index === 'undefined') {
        index = selectionIndex;
      }

      //Initialize option
      let option;

      //Nullable and -1 index given?
      if (isNullable && index === -1) {
        option = null;
      }

      //Otherwise, take from given options
      else {

        //Validate index
        if (
          !hasOptions ||
          typeof index === 'undefined' ||
          typeof options[index] === 'undefined'
        ) {
          return;
        }

        //Get option
        option = options[index];
      }

      //Select option now
      this.selectOption(option, index);
    },

    /**
     * Helper to get the tracking value of an option
     */
    getTrackingValue(option, index) {

      //Get data
      const {trackBy, nullValue} = this;

      //Null value?
      if (option === null) {
        return nullValue;
      }

      //Tracking by index?
      if (trackBy === '$index') {
        return index;
      }

      //Non object? Track by its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have tracking property
      if (!trackBy) {
        throw new Error('Missing track-by property for select box');
      }

      //Validate property
      if (typeof option[trackBy] === 'undefined') {
        throw new Error(`Unknown property '${trackBy}' for select box tracking`);
      }

      //Return the property
      return option[trackBy];
    },

    /**
     * Get the model value
     */
    getModelValue(option, index) {

      //Get data
      const {isNullable, nullValue, asObject} = this;

      //If nullable and null option given, return null value
      if (isNullable && option === null) {
        return nullValue;
      }

      //If returning as object, return the selected option
      if (asObject) {
        return option;
      }

      //Otherwise, return the tracking value of the given option
      return this.getTrackingValue(option, index);
    },

    /**
     * Get label value of an option
     */
    getLabelValue(option) {

      //Get data
      const {labelBy, nullLabel} = this;

      //Null value?
      if (option === null || typeof option === 'undefined') {
        return nullLabel;
      }

      //Non object? Use its value
      if (typeof option !== 'object') {
        return option;
      }

      //Must have label property
      if (!labelBy) {
        throw new Error('Missing label-by property for select box');
      }

      //Validate property
      if (typeof option[labelBy] === 'undefined') {
        throw new Error(`Unknown property '${labelBy}' for select box label`);
      }

      //Return the property
      return option[labelBy];
    },

    /**
     * Find the selected option based on the model value
     */
    findOption(model, options) {

      //Get data
      const {trackBy, nullValue} = this;

      //Nothing selected or null value selected?
      if (typeof model === 'undefined' || model === nullValue) {
        return null;
      }

      //Tracking by index?
      if (trackBy === '$index') {
        if (typeof options[model] !== 'undefined') {
          return options[model];
        }
        return null;
      }

      //Get the model value
      let modelValue = this.getTrackingValue(model, model);

      //Find matching option
      return options
        .find((option, index) => {
          let optionValue = this.getTrackingValue(option, index);
          return (modelValue === optionValue);
        });
    },

    /**
     * Document click handler
     */
    documentClickHandler(event) {

      //Not showing options or clicked on the selectbox?
      if (!this.isShowingOptions || this.$el.contains(event.target)) {
        return;
      }

      //Hide options
      this.hideOptions();

      //Stop event propagation
      event.preventDefault();
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss">

//Variables
$caretSize: 5px;

//Selectbox
.SelectBox {
  position: relative;
  .Input {
    outline: none;
    cursor: pointer;
  }
}
.SelectBox-options {
  @include dropdownBox;
  min-width: 0; //Always match input width
  li {
    @include dropdownBoxItem;
  }
}

//Caret
.Caret {
  display: block;
  position: absolute;
  cursor: pointer;
  right: $spacing;
  top: 50%;
  margin-top: -1px;
  width: 0;
  height: 0;
  border-top: $caretSize solid $colorBlack;
  border-right: $caretSize solid transparent;
  border-left: $caretSize solid transparent;
}
</style>
