import http from '@/services/http';
import Artefact from '@/models/artefact.model';

export default {
  query(filter) {
    return http
      .get('/artefact', filter)
      .then(data => Artefact.createFrom(data, null, 'artefacts'));
  },

  list(filter) {
    return http
      .get('/artefact/list', filter)
      .then(artefacts => Artefact.createFrom(artefacts));
  },

  findById(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .get(`/artefact/${id}`)
      .then(data => Artefact.createFrom(data));
  },

  create(type, data) {
    return http
      .upload(`/artefact/${type}`, data)
      .then(data => Artefact.createFrom(data));
  },

  remove(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .delete(`/artefact/${id}`);
  },
};
