import http from '@/services/http';
import CatalogueItem from '@/models/catalogue-item.model';

export default {
  query(filter) {
    return http
      .get('/catalogue', filter)
      // MHJB: I just can't understand why the server sends objects with photo:path: 'photos/62872159efa647e7d0619114.jpg'
      // but by the time they arrive here they're photo:path: '62872159efa647e7d0619114.jpg'
      // I have shimmed it in the model
      .then(data => CatalogueItem.createFrom(data, null));
  },

  create(data) {
    return http
      .upload('/catalogue', data)
      .then(data => CatalogueItem.createFrom(data));
  },

  update(id, data) {
    return http
      .put(`/catalogue/${id}`, data)
      .then(data => CatalogueItem.createFrom(data));
  },

  discontinue(id) {
    return http
      .post(`/catalogue/${id}/discontinue`)
      .then(data => CatalogueItem.createFrom(data));
  },

  remove(id) {
    return http
      .delete(`/catalogue/${id}`);
  },

  inventory() {
    return http
      .get('/catalogue/inventory')
      .then(data => CatalogueItem.createFrom(data, null));
  },
};
