import BatchApi from '../api/batch.api';
import BaseModel from './base.model';

export default class Batch extends BaseModel {
  save(data, type = 'custom') {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data, type);
    }
  }

  create(data, type) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    return BatchApi
      .create(json, type)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const { id } = this;

    return BatchApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  removeMorble(morbleId) {
    const { id } = this;

    return BatchApi
      .removeMorble(id, morbleId)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const { id } = this;

    return BatchApi.remove(id);
  }

  updateStage(stage) {
    const { id } = this;

    return BatchApi.updateStage(id, stage);
  }
}
