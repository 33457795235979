import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/services/auth';
import store from '@/store';

import ActivityLog from '@/components/activity-log/overview';
import Dashboard from '@/components/dashboard/dashboard';
import User from '@/components/user/user';
import UserOverview from '@/components/user/overview';
import Crop from '@/components/crop/crop';
import Manufacturing from '@/components/manufacturing/overview';
import Inventory from '@/components/inventory/overview';
import Login from '@/components/login/login';
import OAuthCallback from '@/components/oauth/callback';
import Morble from '@/components/morble/morble';
import MorbleOverview from '@/components/morble/overview';
import Gallery from '@/components/gallery/gallery';
import GalleryOverview from '@/components/gallery/overview';
import GalleryArtefacts from '@/components/gallery/artefacts';
import ErrorRoute from '@/components/error/error';
import Catalogue from '@/components/catalogue/overview';

Vue.use(Router);

//NOTE: When props is set to true, the route.params will be set as the component props

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'dashboard', component: Dashboard, meta: { auth: true } },
    { path: '/crop', name: 'crop', component: Crop, meta: { auth: true } },
    { path: '/activity', name: 'activity-log', component: ActivityLog, meta: { auth: true } },
    { path: '/catalogue', name: 'catalogue', component: Catalogue, meta: { auth: true } },
    { path: '/manufacturing', name: 'manufacturing', component: Manufacturing, meta: { auth: true } },
    { path: '/inventory', name: 'inventory', component: Inventory, meta: { auth: true } },
    {
      path: '/user',
      component: User,
      children: [
        { path: '', name: 'user', component: UserOverview },
        { path: ':action?/:id?', component: UserOverview },
      ],
      meta: { auth: true },
    },
    {
      path: '/morble',
      component: Morble,
      children: [
        { path: ':action?/:id?', name: 'morble', component: MorbleOverview },
      ],
      meta: { auth: true },
    },
    {
      path: '/gallery',
      component: Gallery,
      children: [
        { path: ':id?', name: 'gallery', component: GalleryOverview, props: true },
        { path: ':id/artefacts', name: 'gallery.artefacts', component: GalleryArtefacts, props: true },
      ],
      meta: { auth: true },
    },
    { path: '/login', name: 'login', component: Login },
    { path: '/oauth/callback', name: 'oAuthCallback', component: OAuthCallback, props: true },
    { path: '/error', name: 'error', component: ErrorRoute, props: true },
  ],
});

/**
 * Authentication guard
 */
router.beforeEach(async (to, from, next) => {
  auth.init();

  //Clear page data
  store.dispatch('page/clear');

  //Check if route requires authentication and if not authenticated
  if (to.matched.some(route => route.meta.auth)) {
    if (!auth.hasToken()) {
      return next({
        name: 'login',
        query: { redirect: to.name },
      });
    }
  }

  next();
});

export default router;
