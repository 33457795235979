import merge from 'deepmerge';
import production from './production';

/**
 * Staging environment configuration
 */
export default merge.all([production, {

  //CMS
  cms: {
    baseUrl: 'https://staging-cms.morble.co',
  },

  //API
  api: {
    baseUrl: 'https://morble-staging.herokuapp.com',
  },

  //App
  app: {
    baseUrl: 'https://staging.morble.co',
  },

  //QR code
  qrCode: {
    version: 3,
  },

  //Error handling
  errors: {
    log: true,
  },
}]);
