export default [
  {
    id: 'custom',
    name: 'Custom',
  },
  {
    id: 'preset',
    name: 'Preset',
  },
];
