<template>
  <div>
    <div class="AppHeader">
      <nav class="AppNavigation">
        <menu class="Crumbs">
          <li class="Crumb">
            <router-link class="Crumb-link" to="/">
              Morble CMS
            </router-link>
          </li>
          <template v-for="crumb in crumbs">
            <li class="Crumb Crumb--chevron" :key="'c' + crumb.title">
              <i class="Icon">chevron_right</i>
            </li>
            <li class="Crumb" :key="crumb.title">
              <router-link class="Crumb-link" :to="crumb.route">
                {{crumb.title}}
              </router-link>
            </li>
          </template>
        </menu>
        <menu class="Modules" v-if="user">
          <li class="Module">
            <router-link class="Module-link" to="/">
              <i class="Icon">dashboard</i>
              <span>Dashboard</span>
            </router-link>
          </li>
          <li class="Module">
            <router-link class="Module-link" to="/user">
              <i class="Icon">person</i>
              <span>Users</span>
            </router-link>
          </li>
          <li class="Module">
            <router-link class="Module-link" to="/morble">
              <i class="Icon">fiber_manual_record</i>
              <span>Morbles</span>
            </router-link>
          </li>
          <li class="Module">
            <router-link class="Module-link" to="/gallery">
              <i class="Icon">photo</i>
              <span>Galleries</span>
            </router-link>
          </li>
          <!-- <li class="Module">
            <router-link class="Module-link" to="/crop">
              <i class="Icon">crop</i>
              <span>Crop</span>
            </router-link>
          </li> -->
          <li class="Module">
            <router-link class="Module-link" to="/activity">
              <i class="Icon">reorder</i>
              <span>Activity</span>
            </router-link>
          </li>
          <li class="Module">
            <router-link class="Module-link" to="/manufacturing">
              <i class="Icon">precision_manufacturing</i>
              <span>Manufacturing</span>
            </router-link>
          </li>
          <li class="Module">
            <router-link class="Module-link" to="/inventory">
              <i class="Icon">inventory</i>
              <span>Inventory</span>
            </router-link>
          </li>
          <li class="Module">
            <router-link class="Module-link" to="/catalogue">
              <i class="Icon">view_list</i>
              <span>Catalogue</span>
            </router-link>
          </li>
          <li class="Module">
            <a class="Module-link" @click="logout">
              <i class="Icon">power_settings_new</i>
              <span>Logout</span>
            </a>
          </li>
        </menu>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      user: state => state.session.user,
      title: state => state.page.title,
      crumbs: state => state.page.crumbs,
    }),
  },
  methods: {

    /**
     * Logout handler
     */
    logout() {
      this.$auth.logout();
    },
  },
};
</script>

<style lang="scss">
.AppHeader {
  padding: $spacing $spacingLarge;
  background: $colorBgHeader;
}
.AppNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: $fontWeightLight;
}

//Crumbs
.Crumbs {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  height: 42px;
}
.Crumb {
  margin-right: $spacingSmall;
  &:last-child {
    .Crumb-link {
      color: $colorPrimary;
    }
  }
}
.Crumb--chevron {
  color: $colorTextMuted;
}
.Crumb-link {
  color: $colorLighterGray;
}

//Modules
.Modules {
  display: flex;
  align-items: center;
}
.Module {
  width: 3.2rem;
  margin-left: 1.5rem;
  font-size: $fontSizeSmaller;
}
.Module-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $colorLighterGray;
}
</style>
