import http from '@/services/http';
import Role from '@/models/role.model';

export default {
  query() {
    return http
      .get('/role')
      .then(data => Role.createFrom(data));
  },
};
