import UserApi from '@/api/user.api';
import { SET_USER } from './types';

/**
 * Load session user
 */
export const loadUser = async ({ commit }) => {

  //Get user
  const user = await UserApi.me();

  //Commit to store
  commit(SET_USER, user);
};

/**
 * Unload session user
 */
export const unloadUser = ({ commit }) => {

  //Clear from store
  commit(SET_USER, null);
};
