<template>
  <div class="Modal">
    <div class="ModalHeader">
      <h1 v-if="title">{{ title }}</h1>
      <h1 v-else-if="isEdit">Edit {{type}}</h1>
      <h1 v-else>Add {{type}}</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('cancel')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot />
    </div>
    <div class="ModalFooter">
      <button-bar
        :showConfirm="saveable"
        :is-busy="isSaving"
        :label-confirm="labelConfirm"
        :label-cancel="labelCancel"
        @cancel="$emit('cancel')"
        @confirm="save()"
        :is-disabled-confirm="isDisabledConfirm"
      >
        <slot name="secondaryButtons" />
      </button-bar>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isError: false,
      isSaving: false,
    };
  },

  props: {
    model: { type: Object },
    validation: { type: Object },
    type: { type: String },
    onSave: { type: Function },
    isEdit: { type: Boolean },
    title: { type: String },
    labelConfirm: {
      type: String,
      default: 'Save',
    },
    labelCancel: {
      type: String,
      default: 'Cancel',
    },
    saveable: {
      type: Boolean,
      default: true,
    },
    isDisabledConfirm: { type: Boolean },
  },

  emits: ['cancel', 'close'],

  methods: {
    save() {
      const { model, validation } = this;

      if (validation) {
        validation.$touch();
        if (validation.$invalid) {
          return;
        }
      }

      this.isSaving = true;
      this.isError = false;

      this
        .onSave(model)
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isSaving = false);
    },
  },
};
</script>
