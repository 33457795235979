<template>
  <div class="Page">
    <page-header
      type="file"
      :total-items="totalItems"
    >
      <template slot="pageActions">
        <button class="Button Button--s Button--primary" @click="togglePickFiles" v-if="!isPickingFiles && !isUploading">
          <i class="Icon">add</i> Upload files
        </button>
        <button class="Button Button--s" @click="togglePickFiles" v-if="isPickingFiles">
          Cancel
        </button>
      </template>
    </page-header>

    <div v-if="isLoading">Loading... <spinner class="Spinner--inline" /></div>

    <hr v-if="!isLoading && !isPickingFiles && !isUploading">
    <div class="ArtefactPhotos" v-if="!isLoading && !isPickingFiles && !isUploading">
      <div v-for="artefact in artefacts" :key="artefact.id" class="ArtefactPhotoSquare">
        <div class="ArtefactPhotoWrapper">
          <img :src="artefact.thumbUrl" class="ArtefactPhoto" v-if="artefact.hasThumb">
          <i class="Icon ArtefactPhotoPlaceholder" v-else>{{artefact.isPhoto ? 'photo' : 'videocam'}}</i>
        </div>
        <div class="ArtefactPhotoOptions">
          <i class="Icon text-muted is-clickable" @click="remove(artefact)">delete</i>
        </div>
      </div>
    </div>

    <div v-if="isPickingFiles">
      <file-drop
        accept="image/jpeg,image/jpg,image/gif,image/png,video/mp4"
        pattern=".jpeg,.jpg,.gif,.png,.mp4"
        :allow-multiple="true"
        @files="setFiles($event.files)"
      />
    </div>

    <div v-if="isUploading">
      <p>
        Uploading file {{numUploading}} of {{numToUpload}}... <spinner class="Spinner--inline" />
      </p>
    </div>
  </div>
</template>

<script>
import GalleryApi from '@/api/gallery.api';
import ArtefactApi from '@/api/artefact.api';
import {PHOTO, VIDEO} from '@/constants/artefact-types';

export default {
  props: {
    id: String,
  },

  data() {
    return {
      gallery: null,
      artefacts: [],
      isLoading: false,
      isUploading: false,
      isPickingFiles: false,
      numToUpload: 0,
      numUploading: 0,
    };
  },

  computed: {
    totalItems() {
      return this.artefacts.length;
    },
  },

  async created() {
    const { id } = this;

    this.isLoading = true;

    this.gallery = await GalleryApi.findById(id);

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: this.gallery.name,
      crumbs: [
        {
          title: 'Galleries',
          route: {name: 'gallery'},
        },
        {
          title: this.gallery.name,
          route: {name: 'gallery.artefacts', params: {id}},
        },
      ],
    });

    this.artefacts = await ArtefactApi
      .query({ gallery: id })
      .then(data => data.artefacts);

    //No artefacts?
    if (this.artefacts.length === 0) {
      this.togglePickFiles();
    }

    this.isLoading = false;
  },

  methods: {
    togglePickFiles() {
      this.isPickingFiles = !this.isPickingFiles;
    },

    async setFiles(files) {

      //No files?
      if (files.length === 0) {
        return;
      }

      //Already uploading
      if (this.isUploading) {
        return;
      }

      //Flag as uploading
      this.isPickingFiles = false;
      this.isUploading = true;
      this.numToUpload = files.length;
      this.numUploading = 1;

      //Get gallery ID
      const {id} = this.gallery;

      //Upload files one by one
      for (const file of files) {

        //Create form data instance
        const formData = new FormData();
        const {name} = file;
        const type = file.type.match(/image/) ? PHOTO : VIDEO;

        //Set form data
        formData.set('gallery', id);
        formData.set('type', type);
        formData.set('name', name);
        formData.append(type, file, name);

        //Create artefact
        try {
          const artefact = await ArtefactApi.create(type, formData);
          this.artefacts.push(artefact);
        }
        catch (error) {
          this.$err.process(error);
        }

        //Update
        this.numUploading++;
      }

      //Done
      this.isUploading = false;
    },

    /**
     * Remove artefact
     */
    async remove(artefact) {

      //Remove on server
      await artefact.remove();

      //Remove from overview
      const i = this.artefacts.indexOf(artefact);
      if (i !== -1) {
        this.artefacts.splice(i, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ArtefactPhotos {
  display: flex;
  flex-wrap: wrap;
}
.ArtefactPhotoSquare {
  flex: 0 0 20%;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
.ArtefactPhotoWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: $spacingSmall;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ArtefactPhoto {
  max-height: 100%;
  max-width: 100%;
}
.ArtefactPhotoOptions {
  position: absolute;
  right: $spacingSmall;
  top: $spacingSmall
}
.ArtefactPhotoPlaceholder {
  font-size: 72px;
  padding: $spacingSmall;
  color: $colorWhite;
  background: $colorTextMuted;
  border-radius: 5px;
}
</style>
