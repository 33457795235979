import auth from '@/services/auth';
import cfg from '@/services/cfg';
import CatalogueItemApi from '../api/catalogue-item.api';
import BaseModel from './base.model';

export default class CatalogueItem extends BaseModel {
  get thumbUrl() {
    const { photo: { pathThumb }} = this;
    const base = cfg.api.baseUrl;
    const qs = auth.getQueryString();
    const shim = pathThumb.match(/photo/) ? '' : 'photo/';
    return `${base}/catalogue/${shim}${pathThumb}${qs}`;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {
    const json = this.toJSON(data);

    return CatalogueItemApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {
    const json = this.toJSON(data);
    const { id } = this;

    return CatalogueItemApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  discontinue() {
    const { id } = this;

    return CatalogueItemApi.discontinue(id)
      .then(data => this.fromJSON(data));
  }

  remove() {
    const { id } = this;

    return CatalogueItemApi.remove(id);
  }
}
