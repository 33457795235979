<template>
  <div class="Pagination" v-if="numPages > 1">
    <span>Page</span>
    <select-box
      class="Pagination-selectBox"
      :model="page"
      :options="pages"
      @change="setPage($event.value)"
    />
    <span>of {{numPages}} &nbsp;</span>
    <span>
      <a
        @click="previousPage"
        :disabled="isFirstPage"
      ><i class="Icon">chevron_left</i></a>
      <a
        @click="nextPage"
        :disabled="isLastPage"
      ><i class="Icon">chevron_right</i></a>
    </span>
  </div>
</template>

<script>

export default {
  props: {
    page: {
      type: Number,
      default: () => 1,
    },
    numPages: {
      type: Number,
      default: () => 0,
    },
    showAll: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['change'],

  computed: {
    pages() {

      //Initialize
      const pages = [];

      //Create pages array
      if (this.numPages) {
        for (let i = 1; i <= this.numPages; i++) {
          pages.push(i);
        }
      }

      //Add all option if allowed
      if (this.showAll) {
        pages.push('All');
      }

      //Return pages
      return pages;
    },
    isFirstPage() {
      return (this.page === 1);
    },
    isLastPage() {
      return (this.page === this.numPages);
    },
  },

  watch: {
    page() {
      this.onChanges();
    },
    numPages() {
      this.onChanges();
    },
  },

  methods: {
    onChanges() {

      //Reset page if invalid
      if (this.page > this.numPages || this.page < 1) {
        this.setPage(1);
      }
    },

    /**
     * Set specific page
     */
    setPage(page) {
      this.$emit('change', {page});
    },

    nextPage() {
      if (this.page < this.numPages) {
        this.setPage(this.page + 1);
      }
    },

    previousPage() {
      if (this.page > 1) {
        this.setPage(this.page - 1);
      }
    },
  },
};
</script>

<style lang="scss">
.Pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a[disabled] {
    color: $colorTextMuted;
    cursor: default;
  }
  .Icon {
    vertical-align: -10px;
  }
}
.Pagination-selectBox {
  margin: 0 $spacingSmall;
  .InputWrapper {
    width: 4.5rem;
  }
}
</style>
