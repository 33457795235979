<template>
  <label
    class="CheckBox"
    :class="{checked: isChecked(), disabled: isDisabled}"
    @click="toggle"
  >
    <slot />
  </label>
</template>

<script>

export default {
  props: {
    model: {
      type: Boolean,
      default: () => null,
    },
    isInverse: {
      type: Boolean,
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['change'],

  methods: {
    toggle() {

      //Get data
      const {isDisabled, model} = this;

      //Don't toggle when disabled
      if (isDisabled) {
        return;
      }

      //Get boolean value and emit change
      this.$emit('change', {value: !model});
    },

    /**
     * Check if checked
     */
    isChecked() {
      return (this.isInverse ? !this.model : !!this.model);
    },
  },
};
</script>

<style lang="scss">
$radioCheckboxSize: 1.5rem;

//Check boxes
.CheckBox {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: $spacingLarge;
  margin-top: $spacingTiny;
  min-height: 2rem;
  line-height: 2rem;
  &:first-child {
    margin-top: 0;
  }
  &:before {
    content: '';
    background: $colorWhite;
    border: 1px solid $colorBorders;
    position: absolute;
    left: 0;
    width: $radioCheckboxSize;
    height: $radioCheckboxSize;
    line-height: $radioCheckboxSize;
    text-align: center;
    margin-top: calc((2rem - $radioCheckboxSize) / 2);
  }
  &.checked {
    &:before {
      // background: $colorPrimary;
      // border: 1px solid $colorPrimary;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 8px;
      width: 9px;
      height: 18px;
      // border: solid $colorWhite;
      border: solid $colorPrimary;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
  &.disabled {
    color: $colorTextMuted;
    cursor: default;
    &:before {
      border: 1px solid $colorLightestGray;
    }
    &.checked {
      &:before {
        background: $colorLightestGray;
      }
    }
  }
}

//Check box group
.CheckBoxGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.CheckBoxGroup--inline {
  @include breakPointSmall {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .CheckBox {
    white-space: nowrap;
    @include breakPointSmall {
      display: inline-block;
      margin-right: 1.5rem;
      margin-top: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
