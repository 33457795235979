import BaseModel from './base.model';
import GalleryApi from '@/api/gallery.api';

/**
 * Gallery model
 */
export default class Gallery extends BaseModel {

  /**
   * Save
   */
  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  /**
   * Create
   */
  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    //Create
    return GalleryApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  /**
   * Update
   */
  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    //Update
    return GalleryApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  /**
   * Remove
   */
  remove() {

    //Get ID
    const {id} = this;

    //Update
    return GalleryApi
      .remove(id);
  }
}
