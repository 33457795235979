
/**
 * Setup page data
 */
export const setup = async ({commit}, data) => {
  const {title, crumbs} = data;

  commit('setPageTitle', title);
  commit('setPageCrumbs', crumbs);
};

/**
 * Clear page data
 */
export const clear = async ({commit}) => {
  commit('clearPageTitle');
  commit('clearPageCrumbs');
};
