<template>
  <div class="Page">
    <page-header
      type="user"
      :total-items="totalItems"
      :show-search="true"
      @search="search($event.query)"
    >
      <template slot="pageActions">
        <button class="Button Button--s" @click="add">
          <i class="Icon">add</i> Add user
        </button>
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div v-if="isLoading">Loading...</div>
    <div v-else-if="!hasItems">No users yet</div>
    <div v-else>
      <div class="Table">
        <div class="Table-header">
          <div class="Table-col Table-col--name">Name</div>
          <div class="Table-col Table-col--email">Email</div>
          <div class="Table-col Table-col--reference">Reference</div>
          <div class="Table-col Table-col--roles">Roles</div>
          <div class="Table-col Table-col--lastOnline">Last Online</div>
          <div class="Table-col Table-col--dotMenu">&nbsp;</div>
        </div>
        <div
          v-for="user in users"
          :key="user.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--name is-clickable" @click="edit(user)">
            <strong>{{user.name}}</strong>
            <div>
              <span
                class="sso"
                v-for="profile in user.profiles"
                :key="profile.id"
              >
                {{profile.provider}}
              </span>
              </div>
          </div>
          <div class="Table-col Table-col--email">
            <i v-if="!user.verified" class="Icon" title="Email not verified">lock_open</i>
            {{user.email}}
          </div>
          <div class="Table-col Table-col--reference">
            {{user.reference}}
          </div>
          <div class="Table-col Table-col--roles">
            <span v-for="role in user.roles" :key="role.id">{{role.name}}</span>
          </div>
          <div class="Table-col Table-col--lastOnline">
            {{user.dateLastOnline|moment}}
          </div>
          <div class="Table-col Table-col--dotMenu">
            <dot-menu>
              <li><a class="default" @click="edit(user)">Edit</a></li>
              <li class="danger" v-if="user.id !== activeUser.id"><a @click="remove(user)">Remove</a></li>
            </dot-menu>
          </div>
        </div>
      </div>

      <pagination
        :show-all="totalItems < 500"
        :page="page"
        :num-pages="numPages"
        @change="loadPage($event.page)"
      ></pagination>
    </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import RoleApi from '@/api/role.api';
import User from '@/models/user.model';
import ModalEditUser from './modals/edit';
import ModalRemoveUser from './modals/remove';
import {mapState} from 'vuex';

export default {
  data() {
    return {

      //Pagination
      page: 1,
      itemsPerPage: 100,
      totalItems: 0,

      //Data
      users: [],
      roles: [],

      //Flags
      isLoading: false,
    };
  },

  computed: {
    hasItems() {
      return this.users.length > 0;
    },

    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    ...mapState({
      activeUser: state => state.session.user,
    }),
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Users',
      crumbs: [
        {
          title: 'Users',
          route: {name: 'user'},
        },
      ],
    });
  },

  async created() {
    await this.loadUsers();
    await this.loadRoles();
    this.processAction();
  },

  methods: {
    add() {
      const user = new User();
      this.edit(user, false);
    },

    edit(user, isEdit = true) {
      const onSave = model => user
        .save(model)
        .then(() => this.onSaved(user, isEdit));

      const onRemove = () => user
        .remove()
        .then(() => this.onRemoved(user));

      this.$modal.show(ModalEditUser, {
        user, isEdit, onSave, onRemove, roles: this.roles,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    remove(user) {

      //Don't allow self removal
      if (user.id === this.activeUser.id) {
        return;
      }

      //Create remove handler
      const onRemove = () => {
        return user
          .remove()
          .then(() => this.onRemoved(user));
      };

      //Open remove modal
      this.$modal.show(ModalRemoveUser, {
        user,
        onRemove,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    search(q) {
      this.loadUsers({q});
    },

    onSaved(user, isEdit) {

      //Show notice
      this.$notice.show('User saved');

      //Reload users if we added a new one
      if (!isEdit) {
        this.loadUsers();
      }
    },

    onRemoved() {
      this.$notice.show('User removed');
      this.loadUsers();
    },

    loadPage(page) {
      this.page = page;
      this.loadUsers();
    },

    async loadUsers(extra) {
      this.isLoading = true;

      const filter = this.makeFilter(extra);

      await UserApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    async loadRoles() {
      await RoleApi
        .query()
        .then(data => this.roles = data);
    },

    makeFilter(extra) {

      //Initialize filter
      const filter = {};
      const {page, itemsPerPage} = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      //Return filter
      return filter;
    },

    processData(data) {
      const {meta, users} = data;
      this.users = users;
      this.totalItems = meta.total;
    },

    processAction() {
      const { action, id } = this.$route.params;

      if (action === 'edit' && id) {
        const user = this.users.find(user => user.id === id);
        if (user) {
          this.edit(user);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Table-col--lastOnline {
  flex: 0 0 9rem;
}
.Table-col--roles {
  flex: 0 0 8rem;
  span + span:before {
    content: ", ";
  }
}

.sso {
  font-size: $fontSizeSmaller;
  margin-right: 0.4em;
  text-transform: capitalize;
}
</style>
