<template>
  <div class="PageHeader">
    <div class="PageTitle">
      <h2 v-if="plainTitle">{{ plainTitle }}</h2>
      <h2 v-else>
        {{totalItems}} {{type | inflect('pluralize')}}
      </h2>
    </div>
    <div class="PageSearch PageSearch-input" v-if="showSearch">
      <input
        type="search"
        class="Input"
        placeholder="Search"
        v-model="query"
        @search="search()"
      >
    </div>
    <div class="PageActions">
      <slot name="pageActions" />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      query: '',
    };
  },

  props: {
    totalItems: { type: Number },
    type: { type: String },
    showSearch: { type: Boolean },
    plainTitle: { type: String },
  },

  emits: ['search'],

  methods: {
    search() {
      const {query} = this;
      this.$emit('search', {query});
    },
  },
};
</script>

<style lang="scss">
  .PageHeader {
    display: flex;
    align-items: center;
    margin-bottom: $spacing;
    justify-content: space-between;
  }
  .PageTitle {
    flex: 1;
  }
  .PageSearch {
    margin: 0 $spacing;
  }
  .PageSearch-input {
    width: 300px;
  }
  .PageActions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .Button {
      margin-left: $spacingSmall;
    }
  }
</style>
