<template>
  <div class="Page">
    <page-header
      type="gallery"
      :total-items="totalItems"
      :show-search="true"
      @search="search($event.query)"
    >
      <template slot="pageActions">
        <button class="Button Button--s Button--primary" @click="add">
          <i class="Icon">add</i> Add gallery
        </button>
      </template>
    </page-header>

    <pagination
      :show-all="totalItems < 500"
      :page="page"
      :num-pages="numPages"
      @change="loadPage($event.page)"
    ></pagination>

    <div v-if="isLoading">Loading... <spinner class="Spinner--inline" /></div>
    <div v-else-if="!hasItems">No galleries yet</div>
    <div v-else>
      <div class="Table">
        <div class="Table-header">
          <div class="Table-col Table-col--name">Name</div>
          <div class="Table-col Table-col--user">Owner</div>
          <div class="Table-col Table-col--artefacts">Files</div>
          <div class="Table-col">Views</div>
          <div class="Table-col Table-col--dotMenu">&nbsp;</div>
        </div>
        <div
          v-for="gallery in galleries"
          :key="gallery.id"
          class="Table-row"
        >
          <div class="Table-col Table-col--name is-clickable" @click="edit(gallery)">
            <strong>{{gallery.name}}</strong>
          </div>
          <div class="Table-col Table-col--user">
            {{gallery.user ? gallery.user.name : ''}}
          </div>
          <div class="Table-col Table-col--artefacts is-clickable">
            <router-link :to="{name: 'gallery.artefacts', params: {id: gallery.id}}">
              <i class="Icon text-muted">photo</i>
              {{gallery.numArtefacts}} files
            </router-link>
          </div>
          <div class="Table-col">
            {{gallery.numViews}}
          </div>
          <div class="Table-col Table-col--dotMenu">
            <dot-menu>
              <li><a class="default" @click="edit(gallery)">Edit</a></li>
              <li><a @click="remove(gallery)">Remove</a></li>
            </dot-menu>
          </div>
        </div>
      </div>

      <pagination
        :show-all="totalItems < 500"
        :page="page"
        :num-pages="numPages"
        @change="loadPage($event.page)"
      ></pagination>
    </div>
  </div>
</template>

<script>
import UserApi from '@/api/user.api';
import GalleryApi from '@/api/gallery.api';
import Gallery from '@/models/gallery.model';
import ModalEditGallery from './modals/edit';
import ModalRemoveGallery from './modals/remove';

export default {
  props: {
    id: String,
  },

  data() {
    return {

      //Pagination
      page: 1,
      itemsPerPage: 100,
      totalItems: 0,

      //Data
      galleries: [],

      //Flags
      isLoading: false,
    };
  },
  computed: {
    hasItems() {
      return this.galleries.length > 0;
    },
    numPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Galleries',
      crumbs: [
        {
          title: 'Galleries',
          route: {name: 'gallery'},
        },
      ],
    });
  },

  async created() {

    //Load data
    await Promise.all([
      this.loadGalleries(),
      this.loadUsersList(),
    ]);

    //Load gallery if ID present
    if (this.id) {
      try {

        //Load gallery
        const gallery = await GalleryApi.findById(this.id);

        //Edit if found
        if (gallery) {
          this.edit(gallery);
        }
      }
      catch (error) {
        this.$err.process(error);
      }
    }
  },

  methods: {

    /**
     * Add gallery
     */
    add() {
      const gallery = new Gallery();
      this.edit(gallery, false);
    },

    edit(gallery, isEdit = true) {

      //Get lists of users and galleries
      const {users} = this;
      const {galleries} = this;

      //Create save handler
      const onSave = (model) => {
        return gallery
          .save(model)
          .then(() => this.onSaved(gallery, isEdit));
      };

      //Create remove handler
      const onRemove = () => {
        return gallery
          .remove()
          .then(() => this.onRemoved(gallery));
      };

      //Open editing modal
      this.$modal.show(ModalEditGallery, {
        gallery, users, galleries, isEdit, onSave, onRemove,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    /**
     * Remove gallery
     */
    remove(gallery) {

      //Create remove handler
      const onRemove = () => {
        return gallery
          .remove()
          .then(() => this.onRemoved(gallery));
      };

      //Open remove modal
      this.$modal.show(ModalRemoveGallery, {
        gallery,
        onRemove,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    /**
     * Search
     */
    search(q) {
      this.loadGalleries({q});
    },

    /**
     * On saved handler
     */
    onSaved(gallery, isEdit) {

      //Show notice
      this.$notice.show('Gallery saved');

      //Reload galleries if we added a new one
      if (!isEdit) {
        this.loadGalleries();
      }
    },

    /**
     * On removed handler
     */
    onRemoved() {

      //Show notice
      this.$notice.show('Gallery removed');

      //Reload galleries
      this.loadGalleries();
    },

    /**
     * Set new page
     */
    setPage(page) {
      this.page = page;
    },

    /**
     * Load page of items
     */
    loadPage(page) {
      this.setPage(page);
      this.loadGalleries();
    },

    /**
     * Load galleries
     */
    async loadGalleries(extra) {

      //Reset flags
      this.isLoading = true;

      //Get filter and query data
      const filter = this.makeFilter(extra);

      //Query galleries
      await GalleryApi
        .query(filter)
        .then(data => this.processData(data))
        .finally(() => this.isLoading = false);
    },

    /**
     * Load users list
     */
    async loadUsersList() {

      //Load users list
      await UserApi
        .list({fields: 'name'})
        .then(users => this.users = users);
    },

    /**
     * Load galleries list
     */
    async loadGalleriesList() {

      //Load galleries list
      await GalleryApi
        .list({fields: 'name'})
        .then(galleries => this.galleries = galleries);
    },

    /**
     * Make filter
     */
    makeFilter(extra) {

      //Initialize filter
      const filter = {};
      const {page, itemsPerPage} = this;

      //Append limit and offset if page given
      if (page && page !== 'All') {
        filter.limit = itemsPerPage;
        filter.offset = (page - 1) * itemsPerPage;
      }

      //Extra data to append
      if (extra) {
        Object.assign(filter, extra);
      }

      //Return filter
      return filter;
    },

    /**
     * Process data
     */
    processData(data) {

      //Extract data
      const {meta, galleries} = data;

      //Set properties and flags
      this.galleries = galleries;
      this.totalItems = meta.total;
    },
  },
};
</script>

<style scoped lang="scss">
.Table-col--code {
  flex: 0 0 8rem;
}
.Table-col--url {
  flex: 2;
}
</style>
