<template>
  <div class="ButtonBar">
    <div class="ButtonBar-secondary">
      <slot />
    </div>
    <div class="ButtonBar-primary">
      <button
        class="Button"
        v-if="showCancel"
        :disabled="isDisabledCancel || isBusy"
        @click="$emit('cancel')"
      >
        {{labelCancel}}
      </button>
      <button
        class="Button"
        v-if="showConfirm"
        :class="classesConfirm"
        :disabled="isDisabledConfirm || isBusy"
        @click="$emit('confirm')"
      >
        {{labelConfirm}}
        <spinner v-if="isBusy" />
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {

    //Buttons visibility
    showCancel: {
      type: Boolean,
      default: () => true,
    },
    showConfirm: {
      type: Boolean,
      default: () => true,
    },

    //Buttons disabled state
    isDisabledCancel: {
      type: Boolean,
      default: () => false,
    },
    isDisabledConfirm: {
      type: Boolean,
      default: () => false,
    },

    //Buttons labels
    labelCancel: {
      type: String,
      default: () => 'Cancel',
    },
    labelConfirm: {
      type: String,
      default: () => 'Confirm',
    },

    //Button type
    typeConfirm: {
      type: String,
      default: () => 'primary',
    },

    //Convenience property to indicate standard business
    isBusy: {
      type: Boolean,
      default: () => false,
    },
  },

  emits: ['cancel', 'confirm'],

  computed: {
    classesConfirm() {
      return {
        'Button--primary': this.typeConfirm === 'primary',
        'Button--danger': this.typeConfirm === 'danger',
      };
    },
  },
};
</script>

<style lang="scss">
  .ButtonBar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Button + .Button {
      margin-left: $spacingSmall;
    }
  }
  .ButtonBar-primary {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>
