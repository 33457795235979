import http from '@/services/http';
import Morble from '@/models/morble.model';

export default {
  query(filter) {
    return http
      .get('/morble', filter)
      .then(data => Morble.createFrom(data, null, 'morbles'));
  },

  list(filter) {
    return http
      .get('/morble/list', filter)
      .then(morbles => Morble.createFrom(morbles));
  },

  findById(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .get(`/morble/${id}`)
      .then(data => Morble.createFrom(data));
  },

  create(data) {
    return http
      .post('/morble', data);
  },

  update(id, data) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .put(`/morble/${id}`, data);
  },

  duplicate(id, num) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .post(`/morble/${id}`, { num });
  },

  remove(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .delete(`/morble/${id}`);
  },

  /**
   * Upload image for Morble
   */
  uploadImage(id, data) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .upload(`/morble/${id}/image`, data);
  },

  choosePresetImage(id, presetImageId) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .post(`/morble/${id}/choosePresetImage`, { catalogueItemId: presetImageId });
  },
};
