<template>
  <div class="Page">
    <page-header
      plainTitle="Inventory"
    />
    <div v-if="isLoading">Loading...</div>

    <div v-else>
      <h3>Preset morbles available for purchase</h3>

      <div class="Table-header">
        <div class="Table-col Table-col--image">&nbsp;</div>
        <div class="Table-col">Name</div>
        <div class="Table-col">Available</div>
      </div>
      <div
        v-for="item in presetMorbles"
        :key="item.photo._id"
        class="Table-row">
        <div class="Table-col Table-col--image"><img :src="item.thumbUrl"></div>
        <div class="Table-col">{{item.description}}</div>
        <div class="Table-col" :class="colourLevel(item.inventory)">
          {{item.inventory}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogueItemApi from '@/api/catalogue-item.api';

export default {
  data() {
    return {
      isLoading: true,
      presetMorbles: [],
    };
  },

  async created() {
    this.presetMorbles = await CatalogueItemApi.inventory();
    this.isLoading = false;
  },

  methods: {
    colourLevel(available) {
      const { inventory: { levels: { green, orange }}} = this.$cfg;

      if (available >= green) {
        return 'Table-col--green';
      }
      else if (available >= orange) {
        return 'Table-col--orange';
      }
      else {
        return 'Table-col--red';
      }
    },
  },
};
</script>

<style scoped>
.Table-col--image {
  flex: 0 0 4rem;
  display: flex;
  align-items: center;
}

.Table-col--green {
  background-color: rgb(165, 229, 165);
}
.Table-col--orange {
  background-color: rgb(236, 184, 120);
}

.Table-col--red {
  background-color: rgb(224, 145, 145);
}

</style>
