<template>
  <div
    class="Notice"
    :class="{pointer: hideOnClick}"
    @click="click"
  >
    <spinner
      v-if="hasSpinner"
      class="Notice-spinner"
    />
    <i
      class="Icon Icon--s Notice-icon"
      v-if="icon"
    >
      {{icon}}
    </i>
    <p class="Notice-message">
      {{message}}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    message: {
      type: String,
      default: () => '',
    },
    icon: {
      type: String,
      default: () => '',
    },
    hasSpinner: {
      type: Boolean,
      default: () => false,
    },
    hideOnClick: {
      type: Boolean,
      default: () => false,
    },
    onClick: {
      type: Function,
      default: () => function() {},
    },
  },

  emits: ['hide'],

  methods: {
    click() {

      //Hide on click if needed
      if (this.hideOnClick) {
        return this.$emit('hide');
      }

      this.onClick();
    },
  },
};
</script>

<style lang="scss">
.Notice {
  float: left;
  clear: left;
  margin-top: $spacing;
  padding: $spacingSmall;
  border-radius: $borderRadiusNotice;
  background: $colorBlack;
  color: $colorWhite;
  box-shadow: 2px 2px 5px rgba(0,0,0,.3);
  display: flex;
  align-items: center;
}
.Notice--danger {
  background: $colorRed;
}
.Notice-spinner {
  margin-right: $spacingSmall;
}
.Notice-icon {
  margin-right: $spacingSmall;
}
.Notice-message {
  display: inline-block;
}
</style>
