<template>
  <div>
    <div v-if="isLoading">
      Loading...
      <spinner class="Spinner--inline" />
    </div>

    <div v-else class="CataloguePhotos">
      <div v-for="item in catalogue" :key="item.id" class="wrapper">
        <div class="CataloguePhotoSquare">
          <div
            :class="{ MorbleCoverImage: true, 'selected': item.id === value }"
            :style="`background-image: url(${item.thumbUrl})`"
            @click="$emit('input', item.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogueItemApi from '@/api/catalogue-item.api';

export default {
  data() {
    return {
      catalogue: [],
      isLoading: true,
    };
  },

  props: {
    value: { type: String },
  },

  emits: ['input'],

  async created() {
    this.catalogue = await CatalogueItemApi.query({ available: true });
    if (!this.value) {
      this.$emit('input', this.catalogue[0].id);
    }
    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>

.CataloguePhotos {
  display: flex;
  flex-wrap: wrap;
}

.wrapper {
  width: 6rem;
}

.CataloguePhotoSquare {
  margin-right: $spacingMedium;
  margin-bottom: $spacingMedium;
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

</style>
