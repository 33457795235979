import http from '@/services/http';
import Batch from '@/models/batch.model';

export default {
  query(filter) {
    return http
      .get('/batch', filter)
      .then(data => Batch.createFrom(data, null));
  },

  create(data, type) {
    return http.post(`/batch/${type}`, data);
  },

  update(id, data) {
    return http.put(`/batch/${id}`, data);
  },

  findById(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http
      .get(`/batch/${id}`)
      .then(data => Batch.createFrom(data));
  },

  removeMorble(batchId, morbleId) {
    if (!batchId) {
      throw new Error('Missing batch ID');
    }

    if (!morbleId) {
      throw new Error('Missing morble ID');
    }

    return http.post(`/batch/${batchId}/remove-morble/${morbleId}`);
  },

  remove(id) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http.delete(`/batch/${id}`);
  },

  updateStage(id, stage) {
    if (!id) {
      throw new Error('Missing ID');
    }

    return http.post(`/batch/${id}/update-stage/${stage}`)
      .then(data => Batch.createFrom(data));
  },
};
