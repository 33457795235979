<template>
  <modal-edit
    type="batch"
    :model="model"
    :title="isNew ? `Create new ${type} batch` : `Process ${type} batch`"
    :saveable="editMode"
    :on-save="onSave"
    label-cancel="Close"
    :label-confirm="isNew ? 'Create' : 'Save'"
    :is-disabled-confirm="isDisabledConfirm"
    @close="$emit('close')"
    @cancel="$emit('close')"
  >
    <div class="Form">
      <div class="Group">
        <label class="Label">Name</label>

        <input
          class="Input"
          type="text"
          v-model="model.name"
        />
      </div>

      <div v-if="type == 'custom'" class="Group">
        <div v-if="model.morbles && model.morbles.length > 0">
          <label class="Label">Morbles in batch</label>

          <div class="morbles">
            <div v-for="morble in model.morbles" :key="morble.id">
              {{morble.name}}
              <div :class="`Pill Pill--${morble.stage}`">
                {{morble.stage}}
              </div>
              <span v-if="editMode && !isNew" class="remove" @click="remove(morble.id)">
                <i class="Icon">close</i>
              </span>
            </div>
          </div>
        </div>

        <button
          v-if="!editMode && !model.manufactured && !model.active"
          class="Button Button--s"
          @click="editMode = true">
          <i class="Icon">edit</i>
          Add or remove morbles
        </button>

        <div v-if="editMode" class="Group">
          <label class="Label">Available morbles</label>

          <div class="morbles">
            <div v-for="morble in availableMorbles" :key="morble.id">
              <input
                type="checkbox"
                :id="morble.id"
                :value="morble.id"
                @change="onChange"
                v-model="checkedAvailableMorbles"
              />
              <label :for="morble.id">{{morble.name}} ({{morble.user.name}})</label>
            </div>
          </div>

          <button class="Button Button--s" @click="addAll">
            <i class="Icon">add</i>
            Add all available
          </button>
        </div>
      </div>

      <div v-else-if="type==='preset'" class="Group">
        <div v-if="editMode">
          <label class="Label">Cover image</label>

          <choose-preset-image v-model="model.presetImage" />

          <label class="Label">Morbles to generate</label>

          <input
            class="Input"
            type="number"
            min="1"
            v-model="model.presetMorblesToCreate"
          />
        </div>

        <div v-else>
          This batch contains {{model.morbles.length}} morbles.
        </div>
      </div>

    </div>

    <template v-slot:secondaryButtons v-if="!editMode">
      <button
        class="Button"
        v-if="csv && csv.length"
        @click="mark('manufacturing')"
      >
        <json-to-csv
          :jsonData="csv"
          :csvTitle="csvTitle"
        >
          <i class="Icon">file_download</i>
          Export CSV
        </json-to-csv>
      </button><br />

      <button
        v-if="model.manufacturing"
        class="Button"
        @click="mark('manufactured')"
      >
        <i class="Icon">inventory</i>
        Mark manufactured
      </button>

      <button
        v-if="model.manufactured && !model.active"
        class="Button"
        @click="mark('active')"
      >
        <i class="Icon">local_shipping</i>
        Mark active
      </button>
    </template>
  </modal-edit>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash';
import ModalEdit from '@/components/shared/modal-edit';
import ChoosePresetImage from '@/components/shared/choose-preset-image';

export default {
  components: { ModalEdit, ChoosePresetImage },

  data() {
    return {
      model: {
        morbles: [],
        name: '',
        createdAt: null,
        presetMorblesToCreate: null,
        presetImage: null,
        manufacturing: null,
        manufactured: null,
        active: null,
      },
      original: {},
      editMode: false,
      checkedAvailableMorbles: [],
    };
  },

  props: {
    batch: { type: Object, required: true },
    availableMorbles: { type: Array },
    reloadOverview: { type: Function },
    onSave: { type: Function },
    isNew: { type: Boolean },
    type: { type: String, required: true },
  },

  emits: ['close'],

  computed: {
    csv() {
      const {
        app: { baseUrl: appBaseUrl },
        api: { baseUrl: apiBaseUrl },
      } = this.$cfg;

      return this.model?.morbles?.map(
        ({ id, name, code }) =>
        ({
          id,
          name: name || '',
          imagePath: `${apiBaseUrl}/morble/${id}/cover`,
          qrCode: `${appBaseUrl}/${code}`,
        }),
      );
    },

    csvTitle() {
      return this.model.name || this.model.createdAt.format();
    },

    isDisabledConfirm() {
      return isEqual(this.model, this.original);
    },
  },

  async created() {
    this.editMode = this.isNew;
    this.model = this.batch.extract(Object.keys(this.model));
    this.setDefaults();
    this.original = cloneDeep(this.model);
  },

  methods: {
    setDefaults() {
      if (!this.model.name) {
        const today = this.$options.filters.moment(new Date, 'yyyy-MM-DD');
        const { type } = this;
        this.model.name = `${today} ${type} batch`;
      }
      if (!this.model.presetMorblesToCreate) {
        this.model.presetMorblesToCreate = 1;
      }
    },

    onChange(e) {
      const { target: { value, checked} } = e;
      if (checked) {
        const morble = this.availableMorbles.find(({ id }) => id === value);
        this.model.morbles.push(morble);
      }
      else {
        this.model.morbles = this.model.morbles.filter(({ id }) => id !== value);
      }
    },

    addAll() {
      this.model.morbles = this.availableMorbles;
      this.checkedAvailableMorbles = this.availableMorbles.map(({ id }) => id);
    },

    async remove(morbleId) {
      await this.batch.removeMorble(morbleId);
      this.model.morbles = this.model.morbles.filter(({ id }) => id !== morbleId);
      this.reloadOverview();
    },

    async mark(stage) {
      if (['manufactured', 'active'].includes(stage) &&
        !confirm(`Are you sure you want to mark this batch as ${stage}?`)) {
        return;
      }

      const updatedBatch = await this.batch.updateStage(stage);
      this.model = updatedBatch.extract(Object.keys(this.model));
      this.reloadOverview();
    },
  },
};
</script>

<style scoped>
.morbles {
  max-height: 50vh;
  overflow-y: scroll;
  margin-bottom: 1em;
}

.remove {
  cursor: pointer;
}
</style>
