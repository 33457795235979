<template>
  <div class="TagsInput">
    <div class="Input Input--faux">
      <div class="Tag" v-for="tag in tags" :key="tag">
        {{tag}}
        <a @click="removeTag(tag)"><i class="Tag-icon Icon">close</i></a>
      </div>
      <input
        type="text"
        placeholder="Add tag..."
        autocomplete="\uFEFF"
        class="Input Input--embedded"
        :class="{'has-error': isErrorExists}"
        v-model="input"
        @keydown="isErrorExists = false"
        @keydown.enter="confirmInput()"
        @keydown.comma="confirmInput()"
        @keydown.esc="clearInput()"
        @keydown.backspace="removeLastTag($event)"
        @blur="confirmInput()"
      >
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      tags: [],
      input: '',
      isErrorExists: false,
    };
  },
  props: {
    model: {
      type: String,
      default: () => '',
    },
    splitter: {
      type: String,
      default: () => ', ',
    },
  },

  emits: ['change'],

  watch: {
    model() {
      this.determineTags();
    },
  },

  mounted() {
    this.propagateFocus();
    this.determineTags();
  },

  methods: {
    propagateFocus() {
      this.$el.tabIndex = -1;
      this.$el.onfocus = () => {
        const inputs = this.$el.getElementsByTagName('input');
        if (inputs.length) {
          inputs[0].focus();
        }
      };
    },

    determineTags() {
      const {model} = this;
      if (model === '' || model === null || model === undefined) {
        return this.tags = [];
      }
      this.tags = model.split(this.splitter);
    },

    emitChange() {

      //Get the new model value and call on change handler
      const value = this.tags.join(this.splitter);
      this.$emit('change', {value});
    },

    /**
     * Confirm input
     */
    confirmInput() {

      //Trim
      const value = this.input.trim();
      const lower = value.toLowerCase();

      //Empty input
      if (value === '') {
        return;
      }

      //Duplicate input
      if (this.tags.some(tag => tag.toLowerCase() === lower)) {
        this.isErrorExists = true;
        return;
      }

      //Add input
      this.tags.push(value);
      this.input = '';
      this.emitChange();
    },

    /**
     * Clear input
     */
    clearInput() {
      this.input = '';
    },

    /**
     * Remove specific tag
     */
    removeTag(tag) {
      const i = this.tags.indexOf(tag);
      if (i !== -1) {
        this.tags.splice(i, 1);
        this.emitChange();
      }
    },

    /**
     * Remove last tag (only if no input)
     */
    removeLastTag() {
      if (this.input === '' && this.tags.length > 0) {
        this.tags.pop();
        this.emitChange();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.Tag {
  background: $colorWhite;
  border-radius: 2px;
  margin-right: $spacingSmall;
  padding: $spacingTiny $spacingSmall;
  font-size: $fontSizeSmall;
}
.Tag-icon {
  @include iconSize('small');
  color: $colorTextMuted;
}
</style>
