<template>
  <modal-remove
    type="user"
    :name="user.name"
    :on-remove="onRemove"
    @close="$emit('close')"
  >
    <p>This will also remove all their morbles, galleries and photos.</p>
  </modal-remove>
</template>

<script>
import ModalRemove from '@/components/shared/modal-remove';

export default {
  components: { ModalRemove },

  props: {
    user: { type: Object },
    onRemove: { type: Function },
  },

  emits: ['close'],
};
</script>
