<template>
  <modal-edit
    type="user"
    :model="model"
    :is-edit="isEdit"
    :on-save="onSave"
    :on-remove="onRemove"
    @close="$emit('close')"
    @cancel="$emit('close')"
  >
    <div class="Form">
      <div class="Group">
        <label class="Label">Name</label>
        <input
          class="Input"
          type="text"
          v-model="model.name"
        >
      </div>
      <div class="Group">
        <label class="Label">Email</label>
        <input
          class="Input"
          type="text"
          v-model="model.email"
        >
      </div>
      <div class="Group">
        <label class="Label">Reference</label>
        <input
          class="Input"
          type="text"
          v-model="model.reference"
        >
      </div>
      <div class="Group">
        <label class="Label">Roles</label>

        <div v-for="role in roles" v-bind:key="role.id">
          <input
            type="checkbox"
            :id="`role-${role.id}`"
            v-model="model.roles"
            :value="role.id"
            :true-value="role.id"
          />
          <label :for="`role-${role.id}`">{{role.name}}</label>
        </div>
      </div>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modal-edit';

export default {
  components: { ModalEdit },

  data() {
    return {
      model: {
        name: null,
        email: null,
        reference: null,
        roles: [],
      },
    };
  },

  props: {
    user: { type: Object },
    isEdit: { type: Boolean },
    onSave: { type: Function },
    onRemove: { type: Function },
    roles: { type: Array },
  },

  emits: ['close'],

  created() {
    //Create model subset
    this.model = this.user.extract(Object.keys(this.model));

    // dehydrate roles
    if (this.model.roles) {
      this.model.roles = this.model.roles.map(role => role.id ? role.id : role);
    }
  },
};
</script>
