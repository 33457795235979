<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm">
      <spinner v-if="isLoggingIn" />

      <div class="GroupSet" v-else-if="error">
        <p class="text-danger">{{error}}</p>
        <router-link :to="{name: 'login'}">
          <button class="Button Button--s Button--muted">
            Back to login
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';

export default {
  data() {
    return {
      isLoggingIn: true,
      error: '',
    };
  },

  beforeRouteEnter(to, from, next) {
    const {
      access_token: accessToken,
      action,
      scope,
    } = to.query;

    //Got access token, login
    if (accessToken) {

      //Connecting existing user? Go to profile
      if (action === 'connect') {
        //TODO
      }

      //Login
      auth.loginWithToken(accessToken, 'dashboard', scope);
      return next();
    }

    next({ name: 'login' });
  },

  beforeMount() {

    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Login',
    });
  },
};
</script>

<style lang="scss">
</style>
