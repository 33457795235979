<template>
  <modal-remove
    type="gallery"
    :name="gallery.name"
    :on-remove="onRemove"
    @close="$emit('close')"
  >
    <p>This will also remove the photo’s in the gallery.</p>
  </modal-remove>
</template>

<script>
import ModalRemove from '@/components/shared/modal-remove';

export default {
  components: { ModalRemove },

  props: {
    gallery: { type: Object },
    onRemove: { type: Function },
  },

  emits: ['close'],
};
</script>
