import BaseModel from './base.model';
import UserApi from '@/api/user.api';

export default class User extends BaseModel {
  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    //Create
    return UserApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    //Update
    return UserApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  /**
   * Remove
   */
  remove() {

    //Get ID
    const {id} = this;

    //Update
    return UserApi
      .remove(id);
  }

  toJSON(data) {

    //Call parent method
    const json = super.toJSON(data);

    json.roles = BaseModel.onlyId(json.roles);

    return json;
  }
}
