<template>
  <div class="Modal">
    <div class="ModalHeader">
      <h1>Duplicate Morble</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <p>How many morbles would you like to create?</p>
      <div class="Group">
        <input
          class="Input"
          type="number"
          v-model="num"
        >
      </div>
      <slot />
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isDuplicating"
        label-confirm="Duplicate"
        :disabled="isDuplicating"
        @cancel="$emit('close')"
        @confirm="duplicate()"
      />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isError: false,
      isDuplicating: false,
      num: 1,
    };
  },

  props: {
    onDuplicate: { type: Function },
  },

  emits: ['close'],

  methods: {
    duplicate() {
      this.isDuplicating = true;
      this.isError = false;

      this
        .onDuplicate(this.num)
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isDuplicating = false);
    },
  },
};
</script>
