<template>
  <modal-remove
    type="morble"
    :name="morble.name"
    :on-remove="onRemove"
    @close="$emit('close')"
  >
  </modal-remove>
</template>

<script>
import ModalRemove from '@/components/shared/modal-remove';

export default {
  components: { ModalRemove },

  props: {
    morble: { type: Object },
    onRemove: { type: Function },
  },

  emits: ['close'],
};
</script>
