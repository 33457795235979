<template>
  <div class="FileDrop">
    <form ref="form" class="FileDrop-zone" :class="{'dragover': isDragging, 'is-small': isSmall}">
      <p class="FileDrop-instructions">
        <i class="Icon FileDrop-icon">add_a_photo</i><br>
        Drag {{allowMultiple ? 'files' : 'file'}} here<br><br>
        <small>– or –</small>
      </p>
      <label class="FileDrop-label">
        <div class="Button Button--primary FileDrop-button" type="button">
          <i class="Icon">file_upload</i> Choose {{allowMultiple ? 'files' : 'a file'}}
        </div>
        <input class="FileDrop-input" :accept="accept" :multiple="allowMultiple" type="file" @change="handleSelectedFiles"/>
        <small class="text-muted">{{patternLegible}}</small>
      </label>
    </form>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dragAndDropCapable: false,
      isDragging: false,
      file: null,
    };
  },
  props: {
    accept: {
      type: String,
      default: '',
    },
    pattern: {
      type: String,
      default: '',
    },
    allowMultiple: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['file'],

  computed: {
    patternLegible() {
      return this.pattern
        .split(',')
        .join(', ');
    },
  },
  mounted() {

    //Check if drag and drop capable
    this.dragAndDropCapable = this.checkDragAndDropCapable();
    if (!this.dragAndDropCapable) {
      return;
    }

    //Listen to events
    const vm = this;
    const events = [
      'drag', 'drop',
      'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave',
    ];

    //Apply listeners
    for (const type of events) {
      this.$refs.form.addEventListener(type, function(event) {
        event.preventDefault();
        event.stopPropagation();
      }, false);
    }

    //Timeout for leave
    let leaveTimeout;

    //Drag listeners
    this.$refs.form.addEventListener('dragover', function() {

      //Remove timeout
      if (leaveTimeout) {
        clearTimeout(leaveTimeout);
      }

      //Flag as dragging
      vm.isDragging = true;
    });
    this.$refs.form.addEventListener('dragleave', function() {

      //Remove timeout
      if (leaveTimeout) {
        clearTimeout(leaveTimeout);
      }

      //Set new timeout
      leaveTimeout = setTimeout(() => {
        vm.isDragging = false;
      }, 100);
    });

    //Drop listener
    this.$refs.form.addEventListener('drop', event => {
      this.handleDroppedFiles(event);
    }, false);
  },
  methods: {

    /**
     * Check if browser is drag and drop capable
     */
    checkDragAndDropCapable() {

      //Create div element to run some test on
      const div = document.createElement('div');
      return (
        (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window && 'FileReader' in window
      );
    },

    /**
     * Handle dropped files
     */
    handleDroppedFiles(event) {
      this.selectFiles(event.dataTransfer.files);
    },

    /**
     * Handle selected file
     */
    handleSelectedFiles(event) {
      this.selectFiles(event.target.files);
    },

    /**
     * Select files
     */
    selectFiles(files) {

      //Not an array?
      if (!Array.isArray(files)) {
        files = Array.from(files);
      }

      //Anything to do?
      if (files.length === 0) {
        return;
      }

      //Validate type
      const accept = this.accept
        .replace(/,/g, '|')
        .replace(/\+/g, '\\+');
      if (accept && !files.every(file => file.type.match(accept))) {
        return this.isErrorFileType = true;
      }

      //Clear error
      this.isErrorFileType = false;

      //Multiple files allowed
      if (this.allowMultiple) {
        return this.$emit('files', {files});
      }

      //Emit single file
      this.$emit('file', {file: files[0]});
    },
  },
};
</script>

<style lang="scss">
.FileDrop {
  color: $colorTextMuted;
}
.FileDrop-zone {
  display: block;
	text-align: center;
  border: 2px dashed transparent;
  transition: all ease-out .2s;
  padding: 6rem 4rem;
  &.dragover {
    border: 2px dashed $colorTextMuted;
    background: $colorBgDropZone;
  }
  &.is-small {
    padding: 2rem 4rem;
  }
}
.FileDrop-icon {
  font-size: 5rem;
  display: inline-block;
  margin-bottom: 1rem;
}
.FileDrop-instructions {
  font-size: 1.5rem;
}
.FileDrop-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.FileDrop-button {
  margin-top: $spacingLarge;
  margin-bottom: $spacing;
}
.FileDrop-input {
  display: none;
}
</style>
