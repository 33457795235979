<template>
  <div class="Container Container--centered Container--padded">
    <div class="PortalForm">
      <h2>Log in</h2>
      <div class="Group">
        <input
          v-model="credentials.email"
          type="text"
          class="Input"
          placeholder="Email"
        />
      </div>
      <div class="Group">
        <input
          v-model="credentials.password"
          type="password"
          class="Input"
          placeholder="Password"
          @keyup.enter="login"
        />
      </div>
      <p class="text-danger" v-if="isErrorInvalidLogin">Invalid login details</p>
      <div class="Group">
        <button
          class="Button"
          @click="login"
          :disabled="isLoggingIn"
        >
          Login
          <spinner />
        </button>
      </div>

      <div class="or">– or –</div>

      <button
        class="Button Button--google"
        @click="loginWithOAuth('google')"
      >
        <div class="ButtonContent">
          <i class="IconFaux"><img src="/images/icon-google.png"></i>
          Sign in with Google
        </div>
      </button><br />
      <button
        class="Button Button--main Button--facebook"
        @click="loginWithOAuth('facebook')"
      >
        <div class="ButtonContent">
          <i class="IconFaux"><img src="/images/icon-facebook.png"></i>
          Sign in with Facebook
        </div>
        </button>

    </div>
  </div>
</template>

<script>
import auth from '@/services/auth';
import NotAuthenticatedError from '@/errors/not-authenticated';

export default {
  data() {
    return {
      isErrorInvalidLogin: false,
      isLoggingIn: false,
      credentials: {
        email: '',
        password: '',
      },
    };
  },

  beforeRouteEnter(to, from, next) {

    //If we are already authenticated, go to dashboard
    if (auth.hasToken()) {
      return next({name: 'dashboard'});
    }
    next();
  },

  beforeMount() {
    //Setup page details
    this.$store.dispatch('page/setup', {
      title: 'Login',
      crumbs: [
        {
          title: 'Login',
          route: {name: 'login'},
        },
      ],
    });
  },

  methods: {
    async login() {
      const { email, password } = this.credentials;
      const { redirect } = this.$route.query;

      //Reset flags
      this.isErrorInvalidLogin = false;
      this.isLoggingIn = true;

      //Login
      try {
        await auth
          .loginWithCredentials(email, password, redirect)
          .finally(() => this.isLoggingIn = false);
      }
      catch (error) {
        if (error instanceof NotAuthenticatedError) {
          return this.isErrorInvalidLogin = true;
        }

        //Process other errors
        this.$err.process(error);
      }
    },

    loginWithOAuth(provider) {
      auth.loginWithOAuth(provider);
    },
  },
};
</script>

<style lang="scss" scoped>
.PortalForm {
  background: $colorBgPortalForm;
  width: 23rem;
  padding: $spacing $spacingLarge;
  border: 1px solid #ebebf1;
}

.or {
  text-align: center;
}
</style>
