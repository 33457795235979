import Vue from 'vue';
import moment from 'moment';
import AuthApi from '@/api/auth.api';
import cfg from '@/services/cfg';
import { fromBase64 } from '@/helpers/convert/string';

//Storage path for token
export const storagePath = `${cfg.storage.prefix}.auth.accessToken`;

/**
 * Create mixin to load the authentication service as dependency in components
 */
Vue.mixin({
  beforeCreate() {
    const options = this.$options;
    if (options.auth) {
      this.$auth = options.auth;
    }
    else if (options.parent && options.parent.$auth) {
      this.$auth = options.parent.$auth;
    }
  },
});

export default class Token {
  constructor(accessToken) {
    const payload = Token.decode(accessToken);

    if (!payload) {
      return;
    }

    //Remember payload and raw access token string
    this.payload = payload;
    this.accessToken = accessToken;

    //Parse exp/iat values
    this.expires = moment.unix(payload.exp);
    this.issued = moment.unix(payload.iat);
  }

  /**
   * Check if the token is expired
   */
  isExpired() {
    const now = moment();
    return (this.expires && this.expires.isBefore(now));
  }

  /**
   * Check if the token is expiring within the given offset
   */
  isExpiring(offset) {
    const reference = moment().add(offset, 'seconds');
    return (this.expires && this.expires.isBefore(reference));
  }

  /**************************************************************************
   * Storage helpers
   ***/

  /**
   * Read access token from local storage
   */
  static read() {
    return localStorage.getItem(storagePath) || '';
  }

  /**
   * Store access token in local storage
   */
  static store(accessToken) {
    localStorage.setItem(storagePath, accessToken);
    return accessToken;
  }

  /**
   * Clear access token from local storage
   */
  static clear() {
    localStorage.removeItem(storagePath);
    return null;
  }

  /**************************************************************************
   * Decoding helpers
   ***/

  /**
   * Decode access token data
   */
  static decode(accessToken) {

    //Nothing?
    if (!accessToken) {
      return null;
    }

    //Split in parts
    const parts = accessToken.split('.');
    if (parts.length !== 3) {
      return null;
    }

    //Get decoded payload
    try {
      const decoded = fromBase64(parts[1]);
      return JSON.parse(decoded);
    }
    catch (e) {
      return null;
    }
  }

  /**************************************************************************
   * Helpers to obtain tokens
   ***/

  /**
   * Get existing access token
   */
  static existing() {
    const accessToken = Token.read();

    if (!accessToken) {
      return null;
    }

    //Instantiate
    return new Token(accessToken);
  }

  /**
   * Obtain access token from server
   */
  static async obtain(grantType, data) {

    //Prepare request
    const { clientId } = cfg.auth;
    const request = Object.assign({ clientId, grantType }, data || {});

    //Get authentication data
    const { accessToken, scope } = await AuthApi.token(request);
    this.checkCMSLoginScope(scope);

    const token = new Token(accessToken);

    Token.store(accessToken);
    return token;
  }

  static checkCMSLoginScope(scope) {
    const scopes = scope?.split(' ');

    if (!scopes?.includes('cms:login')) {
      throw new Error('Invalid scope');
    }

    return true;
  }

  /**
   * Use a provided access token
   */
  static use(accessToken) {

    //Instantiate token
    const token = new Token(accessToken);

    //Store access token
    Token.store(accessToken);
    return token;
  }
}
