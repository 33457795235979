
//Vue app component
import Vue from 'vue';
import App from './components/app/app.vue';

//Services
import router from './router';
import store from './store';
import cfg from './services/cfg';
import auth from './services/auth';
import errors from './services/errors';

//Service worker
import './register-service-worker';

//Global styles
import './styles/reset.scss';
import './styles/utility.scss';
import './styles/elements.scss';
import './styles/shared.scss';

//3rd party plugins and components
import vModal from 'vue-js-modal';
Vue.use(vModal, {
  dynamic: true,
});
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueJsonToCsv from 'vue-json-to-csv';
Vue.component('JsonToCsv', VueJsonToCsv);

//Plugins
import Notice from './plugins/notice/notice.plugin';
Vue.use(Notice, {});

//Shared components
import Spinner from './components/shared/spinner';
Vue.component('spinner', Spinner);

import SelectBox from './components/shared/select-box';
Vue.component('selectBox', SelectBox);

import CheckBox from './components/shared/check-box';
Vue.component('checkBox', CheckBox);

import CheckBoxes from './components/shared/check-boxes';
Vue.component('checkBoxes', CheckBoxes);

import ButtonBar from './components/shared/button-bar';
Vue.component('buttonBar', ButtonBar);

import Pagination from './components/shared/pagination';
Vue.component('pagination', Pagination);

import TagsInput from './components/shared/tags-input';
Vue.component('tagsInput', TagsInput);

import DotMenu from './components/shared/dot-menu';
Vue.component('dotMenu', DotMenu);

import FileDrop from './components/shared/file-drop';
Vue.component('fileDrop', FileDrop);

import PageHeader from './components/shared/page-header';
Vue.component('pageHeader', PageHeader);

//Filters
import momentFilter from './filters/moment';
Vue.filter('moment', momentFilter);

import inflectFilter from './filters/inflect';
Vue.filter('inflect', inflectFilter);

//Additional key codes
Vue.config.keyCodes.backspace = 8;
Vue.config.keyCodes.comma = 188;

//Global Vue configuration
Vue.config.productionTip = false;
Vue.config.errorHandler = function(error) {
  errors.process(error, false);
};

//Instantiate app
new Vue({
  router,
  store,
  cfg,
  auth,
  errors,
  render: h => h(App),
}).$mount('#app');
