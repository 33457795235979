<template>
  <div class="Modal">
    <div class="ModalHeader ModalHeader--danger">
      <h1>Remove {{type}}</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <p v-if="name">Are you sure you want to remove the {{type}} <strong>{{name}}</strong>?</p>
      <p v-else>Are you sure you want to remove this {{type}}?</p>
      <p v-if="child">This will also remove the associated {{child}}.</p>
      <slot />
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isRemoving"
        label-confirm="Remove"
        type-confirm="danger"
        :disabled="isRemoving"
        @cancel="$emit('close')"
        @confirm="remove()"
      />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isError: false,
      isRemoving: false,
    };
  },

  props: {
    type: { type: String },
    name: { type: String },
    child: { type: String },
    onRemove: { type: Function },
  },

  emits: ['close'],

  methods: {
    remove() {
      this.isRemoving = true;
      this.isError = false;

      this
        .onRemove()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isRemoving = false);
    },
  },
};
</script>
