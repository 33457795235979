import http from '@/services/http';
import Gallery from '@/models/gallery.model';

/**
 * Gallery API service
 */
export default {

  /**
	 * Query
	 */
  query(filter) {
    return http
      .get('/gallery', filter)
      .then(data => Gallery.createFrom(data, null, 'galleries'));
  },

  /**
	 * List
	 */
  list(filter) {
    return http
      .get('/gallery/list', filter)
      .then(galleries => Gallery.createFrom(galleries));
  },

  /**
	 * Find by ID
	 */
  findById(id) {

    //No ID
    if (!id) {
      throw new Error('Missing ID');
    }

    //Get from server
    return http
      .get(`/gallery/${id}`)
      .then(data => Gallery.createFrom(data));
  },

  /**
   * Create
   */
  create(data) {
    return http
      .post('/gallery', data);
  },

  /**
   * Update
   */
  update(id, data) {

    //No ID
    if (!id) {
      throw new Error('Missing ID');
    }

    //Update
    return http
      .put(`/gallery/${id}`, data);
  },

  /**
   * Remove
   */
  remove(id) {

    //No ID
    if (!id) {
      throw new Error('Missing ID');
    }

    //Remove
    return http
      .delete(`/gallery/${id}`);
  },
};
