<template>
  <div class="Page">
    <page-header
      plainTitle="Catalogue"
    >
      <template slot="pageActions">
        <button
          class="Button Button--s Button--primary"
          @click="add"
          v-if="!isUploading">
          <i class="Icon">add</i> Add image
        </button>
      </template>
    </page-header>

    <div v-if="isLoading">Loading... <spinner class="Spinner--inline" /></div>

    <div v-if="!isLoading && !isUploading" class="CataloguePhotos">
      <div
        v-for="item in catalogue"
        :key="item.id"
        class="CataloguePhoto"
        @click="edit(item)"
      >
        <div class="CataloguePhotoSquare">
          <div class="Morble" :style="`background-image: url(${item.thumbUrl})`">
          </div>
        </div>
        <div class="description">
          {{ item.description }}
        </div>
        <div v-if="item.usedInCount">
          Used by {{ item.usedInCount}} morbles
        </div>
        <div v-if="!item.available">
          Discontinued
        </div>
      </div>
    </div>

    <div v-if="isUploading">
      <p>
        Uploading file {{numUploading}} of {{numToUpload}}... <spinner class="Spinner--inline" />
      </p>
    </div>
  </div>
</template>

<script>

import CatalogueItemApi from '@/api/catalogue-item.api';
import ModalEdit from './modals/edit.vue';
import CropModal from '@/components/shared/crop-modal';

export default {
  data() {
    return {
      isLoading: true,
      isUploading: false,
      numUploading: 0,
      numToUpload: 0,
      catalogue: [],
    };
  },

  created() {
    this.loadItems();
  },

  methods: {
    async loadItems() {
      this.isLoading = true;
      this.catalogue = await CatalogueItemApi.query();
      this.isLoading = false;
    },

    add() {
      const onUpload = async formData => {
        const image = await CatalogueItemApi.create(formData);
        this.catalogue.push(image);
      };

      this.$modal.show(CropModal, { onUpload }, {
        clickToClose: false,
        height: 'auto',
        width: 750,
        scrollable: true,
      });
    },

    edit(catalogueItem, isEdit = true) {
      const onSave = model => catalogueItem
        .save(model)
        .then(() => this.onSaved(catalogueItem, isEdit));

      const onRemove = () => {
        if (!confirm('Are you sure you want to remove this item?')) {
          return;
        }

        catalogueItem
          .remove()
          .then(() => {
            this.$modal.hideAll();
            this.$notice.show('Catalogue item removed');
            this.loadItems();
          });
      };

      const onDiscontinue = () => catalogueItem
        .discontinue()
        .then(() => {
          this.$modal.hideAll();
          this.$notice.show('Catalogue item discontinued');
          this.loadItems();
        });

      const onMarkAvailable = () => {
        catalogueItem.available = true;
        catalogueItem.save().then(() => {
          this.$modal.hideAll();
          this.$notice.show('Catalogue item marked available');
          this.loadItems();
        });
      };

      this.$modal.show(ModalEdit, {
        catalogueItem, isEdit, onSave, onRemove, onDiscontinue, onMarkAvailable,
      }, {
        clickToClose: false,
        height: 'auto',
        scrollable: true,
      });
    },

    onSaved(isEdit) {
      this.$notice.show('Catalogue item saved');

      //Reload users if we added a new one
      if (!isEdit) {
        this.loadItems();
      }
    },
  },
};

</script>

<style scoped lang="scss">
.CataloguePhotos {
  display: flex;
  flex-wrap: wrap;
}
.CataloguePhoto {
  flex: 0 0 13rem;
  margin-right: $spacingMedium;
  margin-bottom: $spacingMedium;
  text-align: center;
}

.CataloguePhotoSquare {
  position: relative;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
.Morble {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: $spacingSmall;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #999;
  border-radius: 100%;
  background-size: cover;
}
.Morble:before {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 0px, #ffffff, rgba(255, 255, 255, 0) 58%);
  filter: blur(25px);
  z-index: 2;
}
.Morble:after {
  content: "";
  position: absolute;
  top: 70%;
  left: 15%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle at 50% 0px, black, rgba(0, 0, 0, 0) 20%);
  filter: blur(25px);
  z-index: 2;
}

.CataloguePhotoOptions {
  position: absolute;
  right: $spacingSmall;
  top: $spacingSmall
}

.description {
  margin: 0.5rem 0 0.25rem;
  font-weight: bold;
}
</style>
