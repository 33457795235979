import * as getters from './getters';
import * as actions from './actions';
import mutations from './mutations';

/**
 * Page state object
 */
const state = {
  title: 'Morble',
  crumbs: [],
};

/**
 * Export as Vuex module
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
