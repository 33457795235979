import BaseModel from './base.model';
import MorbleApi from '@/api/morble.api';
import auth from '@/services/auth';
import cfg from '@/services/cfg';

export default class Morble extends BaseModel {
  constructor(data, parent) {
    super(data, parent);

    //Image URL property
    Object.defineProperty(this, 'imageUrl', {
      enumerable: false,
      get() {
        if (!this.image) {
          return '';
        }
        const {id} = this;
        const {path} = this.image;
        const qs = auth.getQueryString();
        const base = cfg.api.baseUrl;
        return `${base}/morble/${id}/image/${path}${qs}`;
      },
    });

    //Image thumb URL property
    Object.defineProperty(this, 'imageThumbUrl', {
      enumerable: false,
      get() {
        if (!this.image) {
          return '/images/no-morble-cover.png';
        }
        const {id} = this;
        const {pathThumb} = this.image;
        const qs = auth.getQueryString();
        const base = cfg.api.baseUrl;
        return `${base}/morble/${id}/image/thumb/${pathThumb}${qs}`;
      },
    });

    // URL property
    Object.defineProperty(this, 'url', {
      enumerable: false,
      get() {
        const {code} = this;
        const path = `${cfg.app.baseUrl}/${code}`;
        return path.replace(/^(http|https):\/\//, '');
      },
    });
  }

  toJSON(data) {

    //Call parent method
    const json = super.toJSON(data);

    //Only ID
    json.gallery = BaseModel.onlyId(json.gallery);
    json.user = BaseModel.onlyId(json.user);

    return json;
  }

  save(data) {
    if (this.id) {
      return this.update(data);
    }
    else {
      return this.create(data);
    }
  }

  create(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);

    //Create
    return MorbleApi
      .create(json)
      .then(data => this.fromJSON(data));
  }

  update(data) {

    //Extend instance data with optionally given data
    const json = this.toJSON(data);
    const {id} = this;

    //Update
    return MorbleApi
      .update(id, json)
      .then(data => this.fromJSON(data));
  }

  duplicate(num) {
    const {id} = this;

    return MorbleApi.duplicate(id, num);
  }

  uploadImage(data) {
    const {id} = this;

    return MorbleApi
      .uploadImage(id, data)
      .then(image => this.image = image);
  }

  choosePresetImage(presetImageId) {
    const {id} = this;

    return MorbleApi.choosePresetImage(id, presetImageId);
  }

  remove() {
    const {id} = this;

    return MorbleApi.remove(id);
  }
}
