<template>
  <modal-edit
    type="gallery"
    :model="model"
    :is-edit="isEdit"
    :on-save="onSave"
    @close="$emit('close')"
    @cancel="$emit('close')"
  >
    <div class="Form">
      <div class="Group">
        <label class="Label">Name</label>
        <input
          class="Input"
          type="text"
          v-model="model.name"
        >
      </div>
      <div class="Group">
        <label class="Label">User</label>
        <select-box
          :model="model.user"
          :options="users"
          label-by="name"
          track-by="id"
          @change="model.user = $event.value"
        />
      </div>
    </div>
  </modal-edit>
</template>

<script>
import ModalEdit from '@/components/shared/modal-edit';

export default {
  components: { ModalEdit },

  data() {
    return {
      model: {
        name: null,
        user: null,
      },
    };
  },

  props: {
    gallery: { type: Object },
    users: { type: Array },
    onSave: { type: Function },
    isEdit: { type: Boolean },
  },

  emits: ['close'],

  created() {
    //Create model subset
    this.model = this.gallery.extract(Object.keys(this.model));
  },
};
</script>

<style scoped>
.Form {
  margin-bottom: 3rem;
}
</style>
