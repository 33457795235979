
/**
 * Mutations for the Session state
 */
export default {

  /**
   * Set page title
   */
  setPageTitle(state, title) {
    state.title = title;
  },

  /**
   * Clear page title
   */
  clearPageTitle(state) {
    state.title = '';
  },

  /**
   * Set page crumbs
   */
  setPageCrumbs(state, crumbs) {
    state.crumbs = crumbs || [];
  },

  /**
   * Clear page crumbs
   */
  clearPageCrumbs(state) {
    state.crumbs = [];
  },
};
