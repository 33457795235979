<template>
  <div v-if="morbles.length" class="Table">
    <h3>
      {{heading}}
    </h3>

    <div class="Table-header">
      <div class="Table-col Table-col--image">&nbsp;</div>
      <div class="Table-col Table-col--name">Name</div>
      <div class="Table-col Table-col--user">Owner</div>
      <div class="Table-col Table-col--gallery">Gallery</div>
      <div class="Table-col">Type</div>
      <div class="Table-col Table-col--code">Code</div>
      <div class="Table-col Table-col--tags">Tags</div>
      <div class="Table-col Table-col--public">Public</div>
      <div class="Table-col">Stage</div>
      <div class="Table-col Table-col--dotMenu">&nbsp;</div>
    </div>
    <div
      v-for="morble in morbles"
      :key="morble.id"
      class="Table-row"
    >
      <div class="Table-col Table-col--image is-clickable" @click="$emit('edit', morble)">
        <img class="MorbleCoverTableImage" :src="morble.imageThumbUrl">
      </div>
      <div class="Table-col Table-col--name is-clickable" @click="$emit('edit', morble)">
        <strong>{{morble.name}}</strong>
      </div>
      <div class="Table-col Table-col--user is-clickable" @click="$emit('edit', morble)">
        {{morble.user ? morble.user.name : ''}}
      </div>
      <div class="Table-col Table-col--gallery">
        <span v-if="morble.gallery">
          <router-link :to="{name: 'gallery.artefacts', params: {id: morble.gallery.id}}">
            {{morble.gallery ? morble.gallery.name : ''}}
          </router-link>
        </span>
      </div>
      <div class="Table-col is-clickable" @click="$emit('edit', morble)">{{morbleType(morble)}}</div>
      <div class="Table-col is-clickable">
        <a @click="$emit('qr', morble)">
          {{morble.code}}
        </a>
      </div>
      <div class="Table-col Table-col--tags is-clickable" @click="$emit('edit', morble)">
        {{morble.tags}}
      </div>
      <div class="Table-col Table-col--public is-clickable" @click="$emit('edit', morble)">
        <i class="Icon text-success" v-if="morble.isPublic">check</i>
      </div>
      <div class="Table-col is-clickable" @click="$emit('edit', morble)">
        <div :class="`Pill Pill--${morble.stage}`">
          {{morble.stage}}
        </div>
      </div>
      <div class="Table-col Table-col--dotMenu">
        <dot-menu>
          <li><a class="default" @click="$emit('edit', morble)">Edit</a></li>
          <li><a @click="$emit('duplicate', morble)">Duplicate</a></li>
          <li class="danger">
            <a @click="$emit('remove', morble)">Remove</a>
          </li>
        </dot-menu>
      </div>
    </div>

  </div>
</template>

<script>
import MorbleTypes from '@/constants/morble-types';

export default {
  props: {
    morbles: {
      type: Array,
      required: true,
    },
    heading: { type: String },
  },

  emits: ['edit', 'qr', 'duplicate', 'remove'],

  methods: {
    morbleType(morble) {
      return MorbleTypes?.find(({id}) => id === morble.type)?.name;
    },
  },
};
</script>

<style scoped lang="scss">
.Table {
  margin-bottom: 2rem;
}
.Table-col--public {
  flex: 0 0 5rem;
}
.Table-col--image {
  flex: 0 0 4rem;
  display: flex;
  align-items: center;
}
</style>
