import * as sentry from '@sentry/browser';
import cfg from '@/services/cfg';

class Sentry {
  constructor() {

    //Set config
    this.config = cfg.sentry;
    this.isEnabled = !!this.config.dsn;

    this.init();
  }

  init() {
    if (!this.isEnabled) {
      return;
    }

    const {env: environment} = cfg;
    const config = Object.assign({environment}, this.config);

    //Initialize
    sentry.init(config);
  }

  captureException(exception) {
    if (!this.isEnabled) {
      return;
    }

    //No exception, or ignoring
    if (!exception || exception.ignoreInSentry) {
      return;
    }

    const {tags} = this.config;

    //Wrap in scope
    sentry.withScope(scope => {

      //Set tags
      for (const tag in tags) {
        if (Object.prototype.hasOwnProperty.call(tags, tag)) {
          scope.setTag(tag, tags[tag]);
        }
      }

      //Set client URL
      scope.setExtra('clientUrl', window.location.href);

      sentry.captureException(exception);
    });
  }
}

/**
 * Export singleton instance
 */
export default new Sentry();
