<template>
  <div class="DotMenu">
    <spinner v-if="isBusy" />
    <button
      v-else
      class="Button Button--dot"
      :class="{'is-open': isOpen}"
      @click="toggle"
    >
      <i class="Icon Icon--only">more_vert</i>
    </button>
    <ul class="DotMenu-dropdown" v-if="isOpen">
      <slot />
    </ul>
  </div>
</template>

<script>

export default {
  data() {
    return {
      isOpen: false,
    };
  },

  props: {
    isBusy: {
      type: Boolean,
      default: () => false,
    },
  },

  mounted() {

    //Create click handler
    const toggle = this.$el.querySelector('.Button--dot');
    const clickHandler = function(event) {
      let {target} = event;
      while (target) {
        if (target === toggle) {
          return;
        }
        target = target.parentNode;
      }
      this.close();
    };

    //Bind click handler
    this.clickHandler = clickHandler.bind(this);

    //Apply event listener
    document.addEventListener('click', this.clickHandler);
  },
  destroyed() {
    document.removeEventListener('click', this.clickHandler);
  },
  methods: {

    /**
     * Close
     */
    close() {
      this.isOpen = false;
    },

    /**
     * Toggle
     */
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss">
.DotMenu {
  position: relative;
}
.DotMenu-dropdown {
  @include dropdownBox;
  li > a {
    @include dropdownBoxItem;
    text-decoration: none;
    &:hover {
      background-color: $colorPrimary;
      color: $colorWhite;
      text-decoration: none;
    }
    &.default {
      font-weight: bold;
    }
  }
  li.danger > a {
    &:hover {
      background-color: $colorRed;
    }
  }
  left: auto;
  right: 0;
  top: 80%;
}
</style>
